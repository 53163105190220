<template>
  <VueFinalModal
    v-model="isVisible"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content3"
    @close="closeModal"
  >
    <div class="modal__content">
      <div v-if="loading" class="loading-spinner">
        <i class="fa-solid fa-spinner fa-spin"></i> Cargando...
      </div>
      <div v-else>
        <!-- User Basic Information Section -->
        <div class="section user-info">
          <h4 class="section-title">Información Personal</h4>
          <div class="user-header">
            <img
              :src="userData?.photo || '/img/user.svg'"
              alt="Profile"
              class="profile-photo"
            />
            <div class="user-main-info">
              <h5>{{ userData?.displayName }}</h5>
              <p class="text-muted">{{ userData?.email }}</p>
            </div>
          </div>
          <div class="info-grid">
            <div class="info-item">
              <strong>DNI:</strong> {{ userData?.dni }}
            </div>
            <div class="info-item">
              <strong>Teléfono:</strong> {{ userData?.phone }}
            </div>
            <div class="info-item">
              <strong>Ciudad:</strong> {{ userData?.city }}
            </div>
            <div class="info-item">
              <strong>Género:</strong> {{ userData?.gender }}
            </div>
            <div class="info-item">
              <strong>Estado Civil:</strong> {{ userData?.maritalStatus }}
            </div>
            <div class="info-item">
              <strong>Nacimiento:</strong> {{ userData?.birthDate }}
            </div>
            <div class="info-item">
              <strong>Código de Promoción:</strong> {{ userData?.promoCode }}
            </div>
            <div class="info-item">
              <strong>Registrado el:</strong> {{ userData?.dateRegister }}
            </div>
          </div>
        </div>

        <!-- Balance Section -->
        <div class="section balances">
          <h4 class="section-title">Balance y Niveles</h4>
          <div class="balance-grid">
            <div class="balance-item total">
              <strong>Balance Total:</strong>
              <span>${{ userBalances?.balanceTotal?.toFixed(2) }}</span>
            </div>
            <div class="balance-item total">
              <strong>Giros disponibles:</strong>
              <span>{{ userBalances?.spins }}</span>
            </div>
            <div class="balance-item total">
              <strong>Ventas realizadas:</strong>
              <span>{{ userBalances?.sales }}</span>
            </div>
            <div class="balance-item levels">
              <strong>Nivel 0:</strong>
              <span>${{ userBalances?.balanceLevel0?.toFixed(2) }}</span>
            </div>
            <div class="balance-item levels">
              <strong>Nivel 1:</strong>
              <span>${{ userBalances?.balanceLevel1?.toFixed(2) }}</span>
            </div>
            <div class="balance-item levels">
              <strong>Nivel 2:</strong>
              <span>${{ userBalances?.balanceLevel2?.toFixed(2) }}</span>
            </div>
            <!-- <div class="balance-item levels">
              <strong>Nivel 3:</strong>
              <span>${{ userBalances?.balanceLevel3?.toFixed(2) }}</span>
            </div>
            <div class="balance-item levels">
              <strong>Nivel 4:</strong>
              <span>${{ userBalances?.balanceLevel4?.toFixed(2) }}</span>
            </div> -->
          </div>
        </div>

        <!-- Promoters Section -->
        <div class="section promoters">
          <h4 class="section-title">Red en la que ingresó</h4>
          <div class="promoter-list">
            <div class="promoter-item">
              <strong>Nivel 1:</strong>
              {{ promoterNames?.userIdLevel1Promoter }}
            </div>
            <div class="promoter-item">
              <strong>Nivel 2:</strong>
              {{ promoterNames?.userIdLevel2Promoter }}
            </div>
            <div class="promoter-item">
              <strong>Nivel 3:</strong>
              {{ promoterNames?.userIdLevel3Promoter }}
            </div>
            <div class="promoter-item">
              <strong>Nivel 4:</strong>
              {{ promoterNames?.userIdLevel4Promoter }}
            </div>
          </div>
        </div>
        <!-- Fetch User Network Section -->
        <div v-if="!userNetwork" class="section fetch-network">
          <h4 class="section-title">Red de Usuario</h4>
          <button
            id="btn_network"
            @click="fetchUserNetwork"
            class="btn btn-primary"
          >
            Obtener Red de Usuario
          </button>
        </div>
        <div v-else>
          <h4 class="section-title">Red de Usuario</h4>
          <div v-for="level in userNetwork" :key="level.level">
            <div class="section promoters">
              <h5>
                Equipo {{ level.level === "level1" ? "Nivel 1" : "Nivel 2" }}
              </h5>
              <DataTable
                :columns="[
                  { title: 'Nombre', data: 'names' },
                  { title: 'Apellido', data: 'lastnames' },
                  {
                    title: 'Balance Total',
                    data: 'balanceTotal',
                    render: (data) => `$${data}`,
                  },
                  { title: 'Ventas', data: 'sales' },
                ]"
                :data="level.promoters"
                class="table tablesorter"
                width="100%"
                :options="options"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
/*eslint-disable */
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";

DataTable.use(DataTablesCore);

export default {
  name: "UserDetailsModal",
  components: {
    VueFinalModal,
    DataTable,
  },
  props: {
    showUserInfo: Boolean,
    selectedUserId: String,
  },
  data() {
    return {
      userId: this.$store.state.user.userId,
      tokenAuth: this.$store.state.tokenAuth,
      role: this.$store.state.user.role,
      loading: false, // Added loading state
      loadingNetwork: false,
      userData: null,
      userBalances: null,
      promoterNames: null,
      userNetwork: null,
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  mounted() {},
  computed: {
    isVisible: {
      get() {
        return this.showUserInfo;
      },
      set(value) {
        this.$emit("update:showUserInfo", value); // Two-way binding for reactivity
      },
    },
  },
  watch: {
    selectedUserId(newValue) {
      if (newValue) {
        this.fetchUserInfo();
      }
    },
  },
  methods: {
    async fetchUserInfo() {
      this.loading = true; // Set loading to true before request
      this.userNetwork = null; // Reset userNetwork when fetching new user info
      try {
        const res = await this.$https.post("/administration/fetchUserInfo", {
          userId: this.userId,
          tokenAuth: this.tokenAuth,
          selectedUserId: this.selectedUserId,
          role: this.role,
        });
        this.userData = res.data.userData;
        this.userBalances = res.data.userBalances;
        this.promoterNames = res.data.promoterNames;
        console.log(res.data);
      } catch (error) {
        if (error.response?.status === 401) {
          this.$store.dispatch("clearAllData");
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            max: 10,
          });
          this.$router.push({ name: "Signin" });
        } else {
          this.$toast.error(error.response?.data?.message || "Error occurred", {
            position: "top-right",
            max: 10,
          });
        }
      } finally {
        this.loading = false; // Reset loading after request completes
      }
    },
    closeModal() {
      this.isVisible = false; // Triggers the prop update in parent
      this.userNetwork = null;
    },
    async fetchUserNetwork() {
      this.loadingNetwork = true;
      document.getElementById("btn_network").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Cargando Red...';

      try {
        const res = await this.$https.post("/administration/fetchUserNetwork", {
          userId: this.userId,
          tokenAuth: this.tokenAuth,
          selectedUserId: this.selectedUserId,
          role: this.role,
        });
        this.userNetwork = res.data.promoterInfoList;
        console.log(res.data);
      } catch (error) {
        if (error.response?.status === 401) {
          this.$store.dispatch("clearAllData");
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            max: 10,
          });
          this.$router.push({ name: "Signin" });
        } else {
          this.$toast.error(error.response?.data?.message || "Error occurred", {
            position: "top-right",
            max: 10,
          });
        }
      } finally {
        this.loadingNetwork = false;
        document.getElementById("btn_network").innerHTML =
          "Obtener Red de Usuario";
      }
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
}

.section {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  margin-bottom: 1.5rem;
  color: #2c3e50;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 2px solid #eee;
  padding-bottom: 0.5rem;
}

.user-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.profile-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}

.user-main-info {
  h5 {
    margin: 0;
    font-size: 1.2rem;
    color: #2c3e50;
  }
  p {
    margin: 0.25rem 0 0;
    font-size: 0.9rem;
  }
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.info-item {
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 4px;

  strong {
    color: #6c757d;
    margin-right: 0.5rem;
  }
}

.balance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.balance-item {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.total {
    grid-column: 1 / -1;
    background-color: #7d0c7e;
    font-size: 1.1rem;
  }

  strong {
    color: #fff;
  }

  span {
    font-weight: 600;
    color: #fff;
  }

  &.levels {
    strong {
      color: #6c757d;
    }

    span {
      font-weight: 600;
      color: #6c757d;
    }
  }
}

.promoter-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.promoter-item {
  padding: 0.75rem;
  background-color: #f8f9fa;
  border-radius: 4px;

  strong {
    color: #6c757d;
    margin-right: 0.5rem;
  }
}

.modal-content4 {
  max-height: 80vh;
  overflow-y: auto;
  padding: 2rem;
}
</style>
