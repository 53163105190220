/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Signin from "../views/Signin.vue";
import perfil from "../views/Perfil.vue";
import editarPerfil from "../views/Dashboard/EditarPerfil.vue";
import NotFound from "../views/404.vue";
import Venta from "../views/Venta.vue";
import Invitacion from "../views/Invitacion.vue";
import Landing from "../views/Landing/Index.vue";
import DatosBancarios from "../views/DatosBancarios.vue";
import Terminos from "../views/Terminos.vue";
import Descripcion from "../views/Descripcion.vue";
import store from "@/store/index";
import RouletteDashboard from "../views/RouletteDashboard.vue";
import Referidos from "../views/Referidos/Index.vue";
import Mas from "../views/Mas.vue";
import transacciones from "../views/Dashboard/Transacciones.vue";
import detalleVentas from "../views/Dashboard/DetalleVentas.vue";
import Administracion from "../views/Administracion/Index.vue";
import recursos from "../views/Dashboard/Recursos.vue";
import vuexStore from "../store/index.js"; // Adjust this path according to your project structure
import PlanesDisponibles from "../views/PlanesDisponibles.vue";
import API from "@/store/axios";

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
    meta: { layout: "full", requiresAuth: false },
  },
  {
    path: "/invitacion/:promoCode?",
    name: "Invitacion",
    component: Invitacion,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/referidos",
    name: "referidos",
    component: Referidos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mi Equipo",
    },
  },
  {
    path: "/dashboard/planes",
    name: "planes",
    component: PlanesDisponibles,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Planes Disponibles",
    },
  },
  {
    path: "/dashboard/banco",
    name: "banco",
    component: DatosBancarios,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Cuenta bancaria",
    },
  },
  {
    path: "/dashboard/mas",
    name: "mas",
    component: Mas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Otras opciones",
    },
  },
  {
    path: "/dashboard/terminos",
    name: "terminos",
    component: Terminos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Términos y condiciones",
    },
  },
  {
    path: "/dashboard/descripcion",
    name: "descripcion",
    component: Descripcion,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Descripción y Términos de XTRIMPRO",
    },
  },
  {
    path: "/dashboard/roulette",
    name: "roulette",
    component: RouletteDashboard,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "¡Gira la ruleta!",
    },
  },
  {
    path: "/venta/:promoCode?",
    name: "Venta",
    component: Venta,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, layout: "horizontal", breadCrumb: "Inicio" },
  },
  {
    path: "/administracion",
    name: "Administracion",
    component: Administracion,
    meta: {
      requiresAuth: true,
      requestAccess: true,
      layout: "horizontal",
      breadCrumb: "Administración",
    },
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { layout: "full" },
  },
  {
    path: "/crearPerfil",
    name: "crearPerfil",
    component: perfil,
    meta: {
      requiresAuth: true,
      meta: { layout: "full" },
    },
  },
  {
    path: "/dashboard/perfil",
    name: "Perfil",
    component: editarPerfil,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Editar perfil",
    },
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { layout: "full" },
  },
  {
    path: "/dashboard/transacciones",
    name: "transacciones",
    component: transacciones,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis transacciones",
    },
  },
  {
    path: "/dashboard/ventas",
    name: "ventas",
    component: detalleVentas,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Mis Referidos",
    },
  },
  {
    path: "/dashboard/recursos",
    name: "recursos",
    component: recursos,
    meta: {
      requiresAuth: true,
      layout: "horizontal",
      breadCrumb: "Recursos",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, _, next) => {
  var condition = navigator.onLine ? "online" : "offline";
  if (condition === "online") {
    const user = vuexStore.state.user;
    const tokenAuth = vuexStore.state.tokenAuth;
    if (!user) {
      if (
        to.path.startsWith("/venta") ||
        to.path.startsWith("/invitacion") ||
        to.path === "/" ||
        to.path === "/login"
      ) {
        return next(); // Let the navigation proceed
      } else if (to.path === "/login") {
        next(); // Allow the navigation to login
      } else {
        next("/login"); // Redirect to login if not on the login page
      }
    } else {
      if (to.meta.requestAccess) {
        if (!tokenAuth) {
          return;
        }
        try {
          // Call the backend API (accessAdminPanel) to check user access
          const response = await API.post("/access/accessAdminPanel", {
            tokenAuth: tokenAuth, // Assuming the user object contains the token
            role: user.role, // Role should be passed for comparison
            userLoginId: user.userLoginId, // Assuming userLoginId is available
          });

          if (response.data.accessAllowed) {
            next(); // If access is allowed, continue to the requested route
          } else {
            next("/login"); // If access is denied, redirect to login
          }
        } catch (error) {
          console.error("Access check failed:", error);
          next("/login"); // If API call fails, redirect to login page
        }
      } else {
        next();
      }
    }
  } else {
    window.location.href =
      window.location.protocol + "//" + window.location.host + "/conexion.html";
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
