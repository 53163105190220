<template>
  <div style="padding: 20px">
    <div class="counts-container">
      <div class="card card-data">
        <strong>Total de usuarios:</strong> {{ totalUsers() }}
      </div>
      <div class="card card-data">
        <strong>Total de Leads:</strong> {{ countSales() }}
      </div>
      <div class="card card-data">
        <strong>Total de Ventas Instaladas:</strong>
        {{ countInstalledSales() }}
      </div>
      <div class="card card-data">
        <strong>Total de Ventas Pendientes:</strong> {{ countPendingSales() }}
      </div>
      <div class="card card-data">
        <strong>Total de Cierres Perdidos:</strong>
        {{ countNotPendingNotInstalledSales() }}
      </div>
      <div class="card card-data">
        <strong>Comisiones por pagar:</strong> ${{ totalBalance.toFixed(2) }}
      </div>

      <div class="card card-data">
        <ol style="margin: 0; padding: 0">
          <div style="padding-bottom: 10px; margin: 0">
            <strong>Top 10 balances:</strong>
          </div>
          <li
            v-for="(balance, index) in topTenBalancesWithNames"
            :key="balance.userId"
            style="
              text-align: left;
              display: flex;
              justify-content: space-between;
            "
          >
            <span>
              <strong>{{ index + 1 }}.</strong>
              {{ balance.names }} {{ balance.lastnames }}:
            </span>
            <strong>${{ balance.balanceTotal.toFixed(2) }}</strong>
          </li>
        </ol>
      </div>
      <div class="card card-data">
        <ol style="margin: 0; padding: 0">
          <div style="padding-bottom: 10px; margin: 0">
            <strong>Top 10 Vendedores:</strong>
          </div>
          <li
            v-for="(seller, index) in topTenSellersWithNames"
            :key="seller.userId"
            style="
              text-align: left;
              display: flex;
              justify-content: space-between;
            "
          >
            <span>
              <strong>{{ index + 1 }}.</strong>
              {{ seller.names }} {{ seller.lastnames }}:
            </span>
            <strong style="padding-left: 10px">{{ seller.sales }}</strong>
          </li>
        </ol>
      </div>
    </div>
    <div
      v-if="usersByCity && Object.keys(usersByCity).length"
      class="city-list"
    >
      <p style="font-size: 18px; font-weight: bold">Usuarios por Ciudad</p>
      <ul
        class="list-group"
        style="display: flex; flex-direction: column; gap: 10px"
      >
        <li
          v-for="(count, city) in usersByCity"
          :key="city"
          class="card card-body"
          style="
            display: flex;
            justify-content: space-between;
            flex-direction: row;
          "
        >
          <strong>{{ city }}:</strong> {{ count }}
        </li>
      </ul>
    </div>
    <p v-else class="no-data">En Desarrollo</p>
  </div>
</template>

<style scoped>
.resumen-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.city-list {
  margin-bottom: 20px;
  width: fit-content;
}

.list-group {
  list-style-type: none;
  padding: 0;
}

.list-group-item {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 10px;
  margin-bottom: 5px;
}

.counts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;
}

.count-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 15px;
  flex: 1 1 calc(20% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.no-data {
  color: #6c757d;
  font-style: italic;
}
</style>

<script>
export default {
  name: "Resumen",
  data() {
    return {
      users: [],
      usersByCity: {},
      sales: [],
      totalBalance: 0,
      topTenBalancesWithNames: [],
      topTenSellersWithNames: [],
    };
  },
  mounted() {
    this.getUsers();
    this.getSales();
    this.fetchTotalBalance();
  },
  methods: {
    getUsers() {
      this.$https
        .post("/administration/fetchUsersAdminStatistics", {
          tokenAuth: this.$store.state.tokenAuth,
          role: this.$store.state.user.role,
          userId: this.$store.state.user.userId,
        })
        .then((res) => {
          this.users = res.data.users;
          this.countUsersByCity(); // Now it's called AFTER data is set
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSales() {
      this.$https
        .post("/administration/fetchSalesAdmin", {
          tokenAuth: this.$store.state.tokenAuth,
          role: this.$store.state.user.role,
          userId: this.$store.state.user.userId,
        })
        .then((res) => {
          this.sales = res.data.sales;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    countSales() {
      return this.sales.length;
    },

    countInstalledSales() {
      return this.sales.filter(
        (sale) => sale.status === "INSTALADO: Contrato instalado",
      ).length;
    },

    countPendingSales() {
      return this.sales.filter((sale) => !sale.completed).length;
    },

    countNotPendingNotInstalledSales() {
      return this.sales.filter(
        (sale) =>
          sale.completed && sale.status !== "INSTALADO: Contrato instalado",
      ).length;
    },

    countUsersByCity() {
      this.usersByCity = this.users
        .filter((user) => user.isVerified) // Only count verified users
        .reduce((acc, user) => {
          let city = user.city || "Sin Ciudad"; // Default to "Sin Ciudad" if no city
          acc[city] = (acc[city] || 0) + 1;
          return acc;
        }, {});

      // Sort cities alphabetically, placing "Sin Ciudad" at the top
      const sortedCities = Object.keys(this.usersByCity).sort((a, b) => {
        if (a === "Sin Ciudad") return -1; // "Sin Ciudad" comes first
        if (b === "Sin Ciudad") return 1;
        return a.localeCompare(b); // Sort alphabetically
      });

      // Create a new object to maintain the sorted order
      this.usersByCity = sortedCities.reduce((acc, city) => {
        acc[city] = this.usersByCity[city];
        return acc;
      }, {});
    },
    totalUsers() {
      return this.users.length;
    },
    fetchTotalBalance() {
      this.$https
        .post("/administration/fetchWalletBalances", {
          tokenAuth: this.$store.state.tokenAuth,
          role: this.$store.state.user.role,
          userId: this.$store.state.user.userId,
        })
        .then((res) => {
          this.totalBalance = res.data.totalBalance;
          this.topTenBalancesWithNames = res.data.topTenBalancesWithNames;
          this.topTenSellersWithNames = res.data.topTenSellersWithNames;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.card-data {
  padding: 20px;
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: row !important;
  gap: 10px;
}
</style>
