<template>
  <UserDetailsModal
    v-model:showUserInfo="showUserInfo"
    :selectedUserId="selectedUserId"
  />

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> USUARIOS
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                  <th>
                    <Skeletor :shimmer="true" height="10" width="100%" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                  <td>
                    <Skeletor :shimmer="true" height="20" width="100%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="users"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th>Registrado el</th>
            <th data-priority="1">Usuario</th>
            <th>DNI</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th data-priority="2">Ciudad</th>
            <th>Invitación</th>
            <th>Venta</th>
            <th>Más</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import UserDetailsModal from "./UserDetailsModal.vue";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    VueFinalModal,
    UserDetailsModal,
  },
  name: "ListadoUsuarios",
  data() {
    return {
      users: [],
      loading: false,
      showUserInfo: false,
      selectedUserId: null,
      columns: [
        {
          data: null,
          render: function (data) {
            const createdAt = new Date(
              data.createdAt._seconds * 1000 +
                data.createdAt._nanoseconds / 1000000,
            );
            return createdAt.toLocaleString("es-ES", {
              timeZone: "America/Guayaquil",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });
          },
        },
        {
          data: null,
          render: function (data) {
            return (
              (data.names ?? data.displayName) + " " + (data.lastnames ?? "")
            );
          },
        },
        {
          data: null,
          render: function (data) {
            return data.dni ?? "Incompleto";
          },
        },
        {
          data: null,
          render: function (data) {
            const phone = data.referrerPhone ? data.referrerPhone : data.phone;
            return phone ?? "Incompleto";
          },
        },
        {
          data: null,
          render: function (data) {
            const email = data.referrerEmail ? data.referrerEmail : data.email;
            return email;
          },
        },
        {
          data: null,
          render: function (data) {
            return data.city ?? "Incompleto";
          },
        },
        {
          data: null,
          render: function (data) {
            return `<button id="copy-btn-${data.promoCode}" class="btn btn-primary btn-sm copy-btn" style="padding: 4px 8px; width: max-content;" data-url="www.xtrimpro.ec/invitacion/${data.promoCode}">
                        Invitación <i class="fa-solid fa-link"></i>
                    </button>`;
          },
        },
        {
          data: null,
          render: function (data) {
            return `<button id="copy-sell-btn-${data.promoCode}" class="btn btn-primary btn-sm copy-sell-btn" style="padding: 4px 8px; width: max-content;" data-url="www.xtrimpro.ec/venta/${data.promoCode}">
                        Venta <i class="fa-solid fa-link"></i>
                    </button>`;
          },
        },
        {
          data: null,
          render: function (data) {
            return `<button id="view-more-btn-${data.userId}" class="btn btn-yellow-normal btn-sm view-more-btn" style="padding: 4px 8px; width: max-content;" data-userid="${data.userId}">
                        Ver más
                    </button>`;
          },
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  mounted() {
    this.getUsuarios();
  },
  methods: {
    async getUsuarios() {
      try {
        this.loading = true;
        const res = await this.$https.post("/administration/fetchUsersAdmin", {
          tokenAuth: this.$store.state.tokenAuth,
          role: this.$store.state.user.role,
          userId: this.$store.state.user.userId,
        });
        this.users = res.data.users;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("clearAllData");
          this.$toast.error(error.response.data.message, {
            position: "top-right",
            max: 10,
          });
          this.$router.push({ name: "Signin" });
        } else if (error.response && error.response.status === 403) {
          this.$router.push({ name: "Dashboard" });
          this.$toast.error("Acceso no autorizado", {
            position: "top-right",
            max: 10,
          });
        } else {
          this.$toast.error(
            error.response ? error.response.data.message : "Error desconocido",
            {
              position: "top-right",
              max: 10,
            },
          );
        }
      } finally {
        this.loading = false;
      }
    },
    async copyUrl(url) {
      try {
        await navigator.clipboard.writeText(url);
        this.$toast.success("Link copiado exitosamente", {
          position: "top-right",
          max: 10,
        });
      } catch (error) {
        this.$toast.error("Error al copiar el link", {
          position: "top-right",
          max: 10,
        });
      }
    },

    refresh() {
      $(document).off("click", ".copy-btn");
      $(document).off("click", ".copy-sell-btn");

      this.$eventBus.emit("reiniciarListadoUsuariosAdmin");
    },
    openModal(userId) {
      this.selectedUserId = userId; // Actualizar la propiedad reactiva
      this.showUserInfo = true;
    },
  },
  mounted() {
    this.getUsuarios();
    $(document).off("click", ".copy-btn");

    $(document).on("click", ".copy-btn", (event) => {
      const url = $(event.target).data("url");
      console.log(url);
      this.copyUrl(url);
    });
    $(document).off("click", ".copy-sell-btn");

    $(document).on("click", ".copy-sell-btn", (event) => {
      const url = $(event.target).data("url");
      console.log(url);
      this.copyUrl(url);
    });

    $(document).off("click", ".view-more-btn");

    $(document).on("click", ".view-more-btn", (event) => {
      const userId = $(event.target).data("userid");
      console.log(userId);
      this.openModal(userId); // Use the new method to open the modal
    });
  },
};
</script>

<style scoped></style>
