<template>
  <VueFinalModal
    v-model="open"
    :esc-to-close="false"
    :click-to-close="false"
    class="modal-container"
    content-class="modal-content mainModal"
    style="
      /* backdrop-filter: blur(10px); */
      background-color: rgba(0, 0, 0, 0.3);
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
      z-index: 101;
      padding: 10px 15px;
    "
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <img
        src="../../src/assets/img/Xtrim/Xtrim-Pro-logo.png"
        class="headerLogo"
        style="max-width: 260px; width: 70%"
      />
      <!-- Safari -->
      <p
        v-if="isPhone && isIOS && isSafari"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 0;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu iPhone o iPad
      </p>

      <p
        v-if="isPhone && isIOS && isSafari"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 1: Haz click en el siguiente ícono
      </p>
      <img
        v-if="isPhone && isIOS && isSafari"
        src="../../src/assets/img/Xtrim/share2.png"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <p
        v-if="isPhone && isIOS && isSafari"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 2: Agrégalo a tu pantalla de inicio
      </p>
      <img
        v-if="isPhone && isIOS && isSafari"
        src="../../src/assets/img/Xtrim/home-access.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <!-- iPhone chrome  -->
      <p
        v-if="isPhone && isIOS && isChromeApp"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 0;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu iPhone o iPad
      </p>

      <p
        v-if="isPhone && isIOS && isChromeApp"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 1: Haz click en el siguiente ícono
      </p>
      <img
        v-if="isPhone && isIOS && isChromeApp"
        src="../../src/assets/img/Xtrim/chrome-share.png"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <p
        v-if="isPhone && isIOS && isChromeApp"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 2: Agrégalo a tu pantalla de inicio
      </p>
      <img
        v-if="isPhone && isIOS && isChromeApp"
        src="../../src/assets/img/Xtrim/home-access.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <!-- Mozilla -->
      <p
        v-if="isPhone && isFirefox"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 0;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu {{ device }}
      </p>

      <p
        v-if="isPhone && isFirefox && isAndroid"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 1: Haz click en el menú de opciones (⋮)
      </p>
      <img
        v-if="isFirefox && isAndroid"
        src="../../src/assets/img/Xtrim/mozilla-menu.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <p
        v-if="isPhone && isFirefox && isAndroid"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 2: Agrégalo a tu pantalla de inicio
      </p>
      <img
        v-if="isPhone && isFirefox && isAndroid"
        src="../../src/assets/img/Xtrim/mozilla-save.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />

      <!-- Mozilla iOS -->

      <p
        v-if="isPhone && isFirefox && isIOS"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 1: Haz click en el menú
      </p>
      <img
        v-if="isPhone && isFirefox && isIOS"
        src="../../src/assets/img/Xtrim/mozilla-ios-menu.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 4px;
        "
      />

      <p
        v-if="isPhone && isFirefox && isIOS"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 2: Haz click en compartir
      </p>
      <img
        v-if="isPhone && isFirefox && isIOS"
        src="../../src/assets/img/Xtrim/mozilla-ios-share.jpg"
        style="
          width: 70%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 4px;
        "
      />
      <p
        v-if="isPhone && isFirefox && isIOS"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        "
      >
        Paso 3: Agrégalo a tu pantalla de inicio
      </p>
      <img
        v-if="isPhone && isFirefox && isIOS"
        src="../../src/assets/img/Xtrim/home-access.jpg"
        style="
          width: 100%;
          max-width: 280px;
          border-radius: 5px;
          margin-bottom: 16px;
        "
      />
      <!-- </div> -->
      <p
        v-if="isMac && isSafari && device === 'computadora'"
        class="text-description"
        style="
          color: #fff;
          width: 80%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 4px;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu Mac
      </p>
      <p
        v-if="isMac && isSafari && device === 'computadora'"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
        "
      >
        Haz click en Archivo, Compartir, y Añadir a Dock.
      </p>

      <p
        v-if="isMac && !isSafari && !isFirefox && device === 'computadora'"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 6px;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu computadora
      </p>

      <!-- Default Case: For Android and Other Devices -->
      <p
        v-if="!isIOS && !isMac && !isFirefox"
        class="text-description"
        style="
          color: #fff;
          width: 70%;
          text-align: center;
          font-size: 16px;
          margin-top: 8px;
          margin-bottom: 8px;
          font-weight: 700;
        "
      >
        Instala ahora nuestra App en tu dispositivo
      </p>

      <div
        align="center"
        style="
          display: flex;
          gap: 8px;
          height: fit-content;
          margin-bottom: 10px;
        "
      >
        <button class="btn btn-md btn-primary" @click="dismissPrompt">
          Ahora no
        </button>
        <button
          v-if="!isSafari && !isFirefox && !isXiamoi && !isIOS"
          class="btn btn-yellow-normal"
          @click="installPWA"
        >
          Instalar app
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { promptPWAInstall } from "@/main.js";
import { mapActions } from "vuex";

export default {
  components: { VueFinalModal },
  props: ["installEvent"],
  data() {
    return {
      device: "",
      isIOS: false,
      isMac: false,
      isLinux: false,
      isWindows: false,
      isSafari: false,
      isFirefox: false,
      open: true,
      ua: null,
      isPhone: false,
      isDesktop: false,
      isChromeApp: false,
      isXiamoi: false,
      isAndroid: false,
    };
  },

  mounted() {
    this.detectDevice();
    // this.checkPWAStatus();
  },

  methods: {
    ...mapActions(["togglePWA"]),
    detectDevice() {
      const userAgent = navigator.userAgent;
      this.ua = userAgent;
      console.log(userAgent);
      this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      this.isMac = /Macintosh|Mac OS/.test(userAgent);
      this.isWindows = /Windows NT/.test(userAgent);
      this.isLinux = /Linux/.test(userAgent) && !/Android/.test(userAgent);
      // this.isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
      this.isSafari =
        /Safari/.test(userAgent) &&
        /AppleWebKit/.test(userAgent) &&
        !/CriOS/.test(userAgent) &&
        !/FxiOS/.test(userAgent) &&
        !/Chrome/.test(userAgent);
      this.isFirefox =
        /Firefox|FxiOS/.test(userAgent) && !/Seamonkey/.test(userAgent);
      this.isAndroid = /Android/.test(userAgent); // Detect Android devices
      this.isXiamoi = /MiuiBrowser/.test(userAgent); // Detects Xiaomi MIUI browser
      this.isPhone = (this.isIOS && /iPhone/.test(userAgent)) || this.isAndroid;
      this.isDesktop = this.isMac || this.isWindows || this.isLinux;
      this.isChromeApp = /CriOS/.test(userAgent); // Detect Chrome app on iPhone

      // const isDesktop = this.isMac || this.isWindows || this.isLinux;

      if (this.isIOS) {
        this.device = "dispositivo iOS";
      } else if (this.isAndroid) {
        this.device = "dispositivo";
      } else if (this.isDesktop) {
        this.device = "computadora";
      } else {
        this.device = "otro dispositivo";
      }

      if ("onbeforeinstallprompt" in window) {
        console.log("beforeinstallprompt is available");
      } else {
        console.log("beforeinstallprompt is NOT available");
      }

      // console.log(window.matchMedia("(display-mode: standalone)").matches);

      // console.log("this.device", this.device);
      // console.log("this.isIOS", this.isIOS);
      // console.log("this.isMac", this.isMac);
      // console.log("this.isWindows", this.isWindows);
      // console.log("this.isLinux", this.isLinux);
      // console.log("this.isSafari", this.isSafari);
      // console.log("this.isFirefox", this.isFirefox);
      // console.log("this.isAndroid", this.isAndroid);
      // console.log("this.isXiamoi", this.isXiamoi);
      // console.log("this.isChromeApp", this.isChromeApp);
      // console.log(
      //   "!isIOS && !isSafari && !isFirefox && !isXiaomi",
      //   !this.isIOS,
      //   !this.isSafari,
      //   !this.isFirefox,
      //   !this.isXiamoi,
      // );
    },
    installPWA() {
      // console.log("Se mete a instalar");
      promptPWAInstall(); // Call the global function
    },
    dismissPrompt() {
      // this.$eventBus.emit("ocultarPWA"); // Emit an event so the parent component can handle this
      this.togglePWA();
    },
  },
};
</script>

<style>
.mainModal {
  background: radial-gradient(
      circle at top left,
      rgb(121, 18, 120),
      transparent 50%
    ),
    radial-gradient(circle at bottom right, rgb(121, 18, 120), transparent 40%),
    rgb(36, 20, 63) !important;
  border: 6px solid #f685ed;
  border-radius: 20px !important;
  box-shadow: 0px 0px 15px 4px rgb(220 82 212 / 43%);
}
</style>

<!-- // if (this.deferredPrompt) {
  //   this.deferredPrompt.prompt(); // Show the install prompt
  //   this.deferredPrompt.userChoice.then((choice) => {
  //     if (choice.outcome === "accepted") {
  //       console.log("User accepted the PWA installation.");
  //     } else {
  //       console.log("User dismissed the PWA installation.");
  //     }
  //     this.deferredPrompt = null; // Clear the prompt so it can’t be reused
  //   });
  // } else {
  //   console.warn("Install prompt not available.");
  // } -->
