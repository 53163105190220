<template>
  <div v-if="step !== 3" class="align-items-center text-center">
    <p class="titleYellowText">Paso {{ step }} de 2 - {{ textStep }}</p>
  </div>

  <form
    v-if="firstStep"
    role="form"
    autocomplete="off"
    @submit.prevent="onSubmitFirstStep"
  >
    <div>
      <div class="row" v-if="!notSupport">
        <div class="pinkPurpleText">
          Comprueba si tu pana, novia o vecino tiene cobertura de Xtrim
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="margin: 10px 0"
        >
          <label
            for="example-text-input"
            class="form-control-label"
            :class="{ purpleLabel: !onDashboard, yellowLabel: onDashboard }"
            style="font-weight: 900; font-size: 12px"
            >Dirección de su domicilio (*):</label
          >

          <GMapAutocomplete
            :value="autocomplete"
            placeholder="Dirección domiciliaria"
            @place_changed="getAddressData"
            required
            :readonly="isDisabled"
            class="form-control col-lg-12"
            style="border: 2px solid #f9cc03; background: white"
          />
        </div>
        <div v-if="loading" class="loading-container">
          <div class="loader"></div>
          <!-- Animated spinner -->
          <p>
            Estamos buscando tu ubicación, acepta el uso de geolocalización en
            tu dispositivo
          </p>
        </div>
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="margin-bottom: 10px"
          v-else
        >
          <label
            for="example-text-input"
            class="form-control-label"
            :class="{ purpleLabel: !onDashboard, yellowLabel: onDashboard }"
            style="font-weight: 900; font-size: 12px"
            >Ubica el marcador (*):</label
          >

          <GMapMap
            @click="mark"
            :center="center"
            :zoom="16"
            map-type-id="terrain"
            style="width: 100%; height: 250px"
            v-if="!loadingAutocomplete"
            :options="{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
              disableDefaultUi: true,
            }"
          >
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position"
              @dragend="handleMarkerDrag"
            />
          </GMapMap>
        </div>

        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <button
            type="submit"
            id="btn_solicitar"
            class="btn btn-yellow-normal"
            style="width: 100%"
            :disabled="isDisabled"
          >
            Siguiente
            <i style="margin-left: 10px" class="fas fa-arrow-circle-right"></i>
          </button>
        </div>
      </div>

      <div v-else class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <svg
            width="100"
            height="100"
            viewBox="-20 0 190 190"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z"
              fill="#4a4a4a"
            />
          </svg>

          <div
            class="alert alert-primary"
            role="alert"
            style="margin: 0px; font-size: 12px"
          >
            <p
              style="
                text-align: center;
                font-size: 14px;
                margin: 0px;
                color: #f9cc03;
              "
            >
              Tu navegador no soporta la API de geolicación. Por favor intente
              con el navegador Chrome, Brave, Mozilla u otro.
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form
    v-if="secondStep"
    role="form"
    autocomplete="off"
    @submit.prevent="onSubmit"
  >
    <div class="row">
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: 900; font-size: 12px"
          :class="{ purpleLabel: !onDashboard, yellowLabel: onDashboard }"
          >Nombre completo (*):</label
        >
        <input
          required
          placeholder="Ingrese nombres y apellidos"
          class="form-control col-lg-12"
          type="text"
          autocomplete="off"
          v-model="displayName"
          style="border: 2px solid #f9cc03; background: white"
        />
      </div>

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: 900; font-size: 12px"
          :class="{ purpleLabel: !onDashboard, yellowLabel: onDashboard }"
          >Número celular (*):</label
        >

        <MazPhoneNumberInput
          type="tel"
          default-country-code="EC"
          style="width: 100%"
          no-flags
          no-example
          required
          show-code-on-list
          size="md"
          v-model="phoneNumber"
          @update="results = $event"
          :translations="{
            countrySelector: {
              placeholder: 'Código',
              error: 'Elija un pais',
            },
            phoneInput: {
              placeholder: 'Nro. celular',
              example: 'Ejemplo:',
            },
          }"
        />
      </div>

      <!-- <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-bottom: 10px"
      >
        <label
          for="example-text-input"
          class="form-control-label"
          style="font-weight: bold; font-size: 12px"
          >Correo electrónico (*):</label
        >
        <input
          placeholder="Ingrese su correo electrónico"
          class="form-control col-lg-12"
          required
          type="email"
          autocomplete="off"
          v-model="email"
        />
      </div> -->

      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
        style="justify-content: space-between; display: flex"
      >
        <button
          @click="goBack"
          class="btn btn-secondary"
          :disabled="isDisabled"
        >
          <i style="margin-right: 10px" class="fas fa-arrow-circle-left"></i>
          Atrás
        </button>

        <button
          type="submit"
          id="btn_submit"
          class="btn btn-yellow-normal"
          :disabled="isDisabled"
        >
          Solicitar servicio
        </button>
      </div>
    </div>
  </form>
  <div v-if="step === 3" class="resultContainer">
    <p class="titleYellowText">
      ¡Tu {{ onDashboard ? "referido" : "solicitud" }} se envió exitosamente!
    </p>

    <p
      style="
        font-weight: 600;
        font-size: 16px;
        padding: 10px 20px 0 20px;
        text-align: center;
        margin: 0 0 10px 0;
      "
      :class="{ purplePlainText: !onDashboard, whitePlainText: onDashboard }"
    >
      En breve un asesor se contactará
      {{ onDashboard ? "con tu referido" : "contigo" }} para continuar la
      contratación del Mejor Internet del Ecuador
    </p>
    <p
      style="
        color: white;
        font-weight: 500;
        font-size: 14px;
        padding: 10px 20px 10px 20px;
        text-align: center;
        margin: 10px 0 0 0;
        max-width: 370px;
        text-align: center;
        background-color: #540355;
        border-radius: 12px;
      "
    >
      Si tienes alguna duda o inconveniente, no dudes en escribirnos por
      <a
        target="_blank"
        href="https://api.whatsapp.com/send?phone=593995756176&text=Hola,%20necesito%20ayuda..."
        style="color: #19b251; font-weight: 700"
        >Whatsapp
        <svg
          width="22"
          style="margin-left: 6px"
          height="22"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="#19b251"
        >
          <path
            d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
          />
        </svg>
      </a>
    </p>
  </div>
</template>

<script>
/*eslint-disable */

import { debounce } from "lodash";

export default {
  name: "Venta",
  components: {},
  props: ["promoCode", "tokenAuth", "onDashboard"],
  data() {
    return {
      isDisabled: false,
      displayName: "",
      dni: "",
      dniRespaldo: "",
      step: 1,
      phoneNumber: null,
      firstStep: true,
      secondStep: false,
      results: null,
      hasPhone: false,
      email: "",
      textStep: "Ubicación de domicilio",
      Ubication: [],
      center: {},
      markers: [],
      geocoder: null,
      loadMap: true,
      plusCode: "",
      notSupport: false,
      city: "",
      autocomplete: "",
      emailRespaldo: "",
      phoneRespaldo: "",
      idVenta: "",
      loading: true,
      loadingAutocomplete: true,
      isMapLoaded: false,
      isGponAvailable: false,
    };
  },
  methods: {
    getAddressData(request) {
      this.autocomplete = request.formatted_address;
      let location = request.geometry.location;

      this.center = {
        lat: parseFloat(request.geometry.location.lat()),
        lng: parseFloat(request.geometry.location.lng()),
      };

      this.markers = [
        {
          position: location,
        }, // Along list of clusters
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    loadMapa() {
      this.loading = true;
      if (this.loadMap) {
        this.loadMap = false;

        if (!("geolocation" in navigator)) {
          this.$toast.error(
            "La geolocalización no está soportado para este navegador",
            {
              position: "top-right",
              max: 10,
            },
          );

          this.notSupport = true;

          return;
        }

        // get position
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            this.center = {
              lat: parseFloat(pos.coords.latitude),
              lng: parseFloat(pos.coords.longitude),
            };

            this.markers = [
              {
                position: {
                  lat: parseFloat(pos.coords.latitude),
                  lng: parseFloat(pos.coords.longitude),
                },
              }, // Along list of clusters
            ];

            this.geocoder
              .geocode({ location: this.center })
              .then((response) => {
                if (response.results[0]) {
                  this.plusCode = response.results[0].formatted_address;
                  this.autocomplete = response.results[1].formatted_address;
                  this.city =
                    response.results[0].address_components[2].long_name;
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e))
              .finally(() => {
                this.loading = false;
              });
          },
          (err) => {
            this.loading = false;
            this.$toast.error(err.message, {
              position: "top-right",
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          },
        );
      }
    },

    loadAutocomplete(retryCount = 0, maxRetries = 10) {
      console.log("Load autocomplete attempt:", retryCount);
      if (retryCount >= maxRetries) {
        this.$toast.error(
          "No se pudo cargar Google Maps API después de varios intentos.",
          {
            position: "top-right",
            timeout: 5000,
          },
        );
        return;
      }
      this.loadingAutocomplete = true;

      if (!("geolocation" in navigator)) {
        this.$toast.error(
          "La geolocalización no está soportado para este navegador",
          {
            position: "top-right",
            max: 10,
          },
        );

        this.notSupport = true;

        return;
      }
      if (typeof google === "undefined" || !google.maps) {
        console.log("Google Maps API no está disponible. Reintentando...");
        setTimeout(() => {
          this.loadAutocomplete(retryCount + 1, maxRetries);
        }, 1000); // Retry after 1 second
        return;
      }

      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.center = {
            lat: parseFloat(pos.coords.latitude),
            lng: parseFloat(pos.coords.longitude),
          };

          this.markers = [
            {
              position: {
                lat: parseFloat(pos.coords.latitude),
                lng: parseFloat(pos.coords.longitude),
              },
            }, // Along list of clusters
          ];
          this.geocoder = new google.maps.Geocoder();

          this.geocoder
            .geocode({ location: this.center })
            .then((response) => {
              if (response.results[0]) {
                this.plusCode = response.results[0].formatted_address;
                this.autocomplete = response.results[1].formatted_address;
                this.city = response.results[0].address_components[2].long_name;
              }
            })
            .catch((e) => console.log("Geocoder failed due to: " + e))
            .finally(() => {
              this.loading = false;
              this.loadingAutocomplete = false;
            });
        },
        (err) => {
          this.loadingAutocomplete = false;
          if (err.message === "User denied Geolocation") {
            this.$toast.error(
              "Geolocalización rechazada, actívala para poder usar el sitio",
              {
                position: "top-right",
                timeout: 6010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              },
            );
          } else {
            this.$toast.error(err.message, {
              position: "top-right",
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        },
      );
    },

    handleMarkerDrag(e) {
      this.center = {
        lat: parseFloat(e.latLng.lat()),
        lng: parseFloat(e.latLng.lng()),
      };

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    mark(event) {
      this.center = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };

      this.markers = [
        {
          position: {
            lat: parseFloat(event.latLng.lat()),
            lng: parseFloat(event.latLng.lng()),
          },
        }, // Along list of clusters
      ];

      this.geocoder
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
            this.autocomplete = response.results[1].formatted_address;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    onSubmit(event) {
      event.preventDefault();

      if (this.hasPhone) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Solicitando';

        this.isDisabled = true;

        this.$https
          .post("/ventas/registerSale/", {
            promoCode: this.promoCode,
            displayName: this.displayName,
            phone: this.phoneNumber,
            address: this.autocomplete,
            latitude: this.center.lat,
            longitude: this.center.lng,
            plusCode: this.plusCode,
            city: this.city,
            isGponAvailable: this.isGponAvailable,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;
            if (!this.onDashboard) {
              this.$swal({
                title: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Entendido",
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }

            this.secondStep = false;
            this.firstStep = false;
            this.step = 3;
            this.$eventBus.emit("reiniciarListadoVentas");
          })
          .catch((error) => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      } else {
        this.$toast.error("El número celular es un campo obligatorio", {
          position: "top-right",
          max: 10,
        });
      }
    },

    goBack() {
      if ((this.step = 2)) {
        this.step = 1;
        this.isGponAvailable = false;
        this.firstStep = true;
        this.secondStep = false;
        this.textStep = "Ubicación de domicilio";
      }
    },

    onSubmitFirstStep(event) {
      event.preventDefault();

      this.isDisabled = true;

      document.getElementById("btn_solicitar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Comprobando cobertura';

      this.$https
        .post("/ventas/checkCoverage/", {
          tokenAuth: this.tokenAuth,
          latitude: this.center.lat,
          longitude: this.center.lng,
        })
        .then((response) => {
          document.getElementById("btn_solicitar").innerHTML =
            'Siguiente <i  style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>';
          this.isDisabled = false;

          if (response.data.code == 200) {
            this.isDisabled = false;
            this.step = 2;
            this.firstStep = false;
            this.secondStep = true;
            this.textStep = "Información general";
            this.isGponAvailable = response.data.isGponAvailable;
          } else {
            if (response.data.code == 405) {
              this.$swal({
                title: response.data.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Entendido",
                focusConfirm: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
              document.getElementById("btn_solicitar").innerHTML =
                'Siguiente <i  style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>';
            }
          }
        })
        .catch((error) => {
          this.isDisabled = false;
          document.getElementById("btn_solicitar").innerHTML =
            'Siguiente <i  style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>';
          this.$toast.error("Ocurrio un error " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    checkDNI() {
      if (this.dni == "") {
        this.displayName = "";
        this.dniRespaldo = "";
      } else if (this.dni != this.dniRespaldo) {
        document.getElementById("btn_submit").innerHTML =
          '<i class="fas fa-spinner fa-pulse"></i>  Comprobando cédula, espere';

        this.isDisabled = true;

        this.$https
          .post("/profile/checkDniExists/", {
            tokenAuth: this.tokenAuth,
            cedula: this.dni,
          })
          .then((response) => {
            document.getElementById("btn_submit").innerHTML =
              "Solicitar servicio";
            this.isDisabled = false;

            if (response.data.code == 200) {
              this.dniRespaldo = this.dni;
            } else {
              this.dni = "";
              this.dniRespaldo = "";

              this.displayName = "";
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });
            }
          })
          .catch((error) => {
            this.dni = "";

            this.displayName = "";

            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          });
      }
    },

    // checkPhone() {
    //   if (this.phoneNumber == "") {
    //     this.phoneRespaldo = "";
    //   } else if (this.phoneNumber != this.phoneRespaldo) {
    //     document.getElementById("btn_submit").innerHTML =
    //       '<i class="fas fa-spinner fa-pulse"></i>  Comprobando celular, espere';

    //     this.isDisabled = true;

    //     this.$https
    //       .post("/profile/checkUserBan/", {
    //         tokenAuth: this.tokenAuth,
    //         dato: this.phoneNumber,
    //       })
    //       .then((response) => {
    //         document.getElementById("btn_submit").innerHTML =
    //           "Solicitar servicio";
    //         this.isDisabled = false;

    //         if (response.data.code == 200) {
    //           this.phoneRespaldo = this.phoneNumber;
    //         } else {
    //           this.phoneNumber = "";
    //           this.phoneRespaldo = "";

    //           this.$toast.error(response.data.message, {
    //             position: "top-right",
    //             max: 10,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         this.phoneNumber = "";

    //         this.$toast.error("Ocurrió un error inesperado", {
    //           position: "top-right",
    //           max: 10,
    //         });
    //       });
    //   }
    // },

    // checkEmail() {
    //   if (this.email == "") {
    //     this.emailRespaldo = "";
    //   } else if (this.email != this.emailRespaldo) {
    //     document.getElementById("btn_submit").innerHTML =
    //       '<i class="fas fa-spinner fa-pulse"></i>  Comprobando email, espere';

    //     this.isDisabled = true;

    //     this.$https
    //       .post("/profile/checkUserBan/", {
    //         tokenAuth: this.tokenAuth,
    //         dato: this.email,
    //       })
    //       .then((response) => {
    //         document.getElementById("btn_submit").innerHTML =
    //           "Solicitar servicio";
    //         this.isDisabled = false;

    //         if (response.data.code == 200) {
    //           this.emailRespaldo = this.email;
    //         } else {
    //           this.email = "";
    //           this.emailRespaldo = "";

    //           this.$toast.error(response.data.message, {
    //             position: "top-right",
    //             max: 10,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         this.email = "";

    //         this.$toast.error("Ocurrió un error inesperado", {
    //           position: "top-right",
    //           max: 10,
    //         });
    //       });
    //   }
    // },
  },
  computed: {},
  watch: {
    results(newValue) {
      if (newValue.isValid) {
        this.phoneNumber = newValue.e164;
        this.hasPhone = true;

        // this.checkPhone();
      } else {
        this.hasPhone = false;
      }
    },
    displayName() {
      this.displayName = this.displayName.toUpperCase();
    },
  },
  mounted() {
    // this.cargarScript();
    // this.instanceMap();
    this.loadAutocomplete();
    this.debouncedGetAddressData = debounce(this.getAddressData, 300); // 300 ms debounce
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.loading-container {
  text-align: center;
  margin-top: 20px;
}

.loader {
  border: 5px solid #f9cc03; /* Light grey */
  border-top: 5px solid #7d0c7e; /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container p {
  font-size: 16px;
  color: white;
}

.resultContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.pinkPurpleText {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Ubuntu;
  font-weight: 300;
  margin: 0 10px;
  border-radius: 16px;
  gap: 8px;
  margin-top: 8px;
  text-align: center;
  width: fit-content;
  font-size: 14px;
  font-weight: 900;
}

.purpleLabel {
  color: #7d0c7e;
}
.yellowLabel {
  color: #f9cc03;
}

:deep(.m-phone-number-input.--responsive .m-input-wrapper) {
  border: 2px solid #f9cc03 !important;
}

:deep(.m-select-chevron) {
  margin-left: 8px !important;
}

:deep(.m-select-input__toggle-button .maz-custom) {
  padding: 0 !important;
}

:deep(.m-input-wrapper-right) {
  padding: 0 !important;
}

.titleYellowText {
  text-align: center;
  font-family: Ubuntu;
  font-weight: 900;
  color: #7d0c7e;
  font-size: 14px;
  text-shadow:
    2px 2px 0px white,
    -2px -2px 0px white,
    2px -2px 0px white,
    -2px 2px 0px white;
  background: #f9cc03;
  border-radius: 12px;
  padding: 3px 12px;
  box-shadow: 0px 2px 10px 2px #7c0c7e48;
}

.purplePlainText {
  color: #7d0c7e;
}

.whitePlainText {
  color: white;
}
</style>
