<template>
  <div>
    <div class="menuContainer">
      <div style="padding: 0px; height: 100vh" class="container">
        <v-lazy-image
          :src="photo"
          style="
            object-fit: cover;
            object-position: top;
            height: 100%;
            width: 100%;
          "
        />
      </div>

      <div class="responsiveContainer">
        <div class="headerContainer">
          <img
            src="../../src/assets/img/Xtrim/Xtrim-Pro-logo.png"
            class="headerLogo"
            style="max-width: 260px; width: 100%"
          />
          <p
            class="rounded-pill"
            style="
              position: absolute;
              bottom: -16px;
              padding: 5px 10px;
              background: rgb(170, 50, 189);
              background: linear-gradient(
                68deg,
                rgba(170, 50, 189, 1) 0%,
                rgba(217, 76, 225, 1) 100%
              );
              border: 1px solid #ec62ea;
              font-size: 14px;
              color: white;
              font-family: Ubuntu;
              font-weight: 900;

              margin: 0;
              transform: translateX(-50%);
              left: 50%;
              width: max-content;
            "
          >
            ¡YA ESTÁS CERCA DE SER UN PRO!
          </p>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0 -40px 0;
            padding: 0;
          "
        >
          <img
            src="../../src/assets/img/Xtrim/como-deseas-ingresar.webp"
            style="max-width: 260px; width: 100%"
          />
          <img
            src="../../src/assets/img/Xtrim/personaje-pro.webp"
            style="max-width: 240px; width: 100%; margin-top: -35px"
          />
        </div>

        <formulario :promo-code="promoCode" />

        <hr class="horizontal dark" style="margin-top: 25px; width: 100%" />

        <footer-component />
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import Formulario from "./Formulario.vue";
import PWAPrompt from "./PWAPrompt.vue";
import FooterComponent from "./Layouts/Footer.vue";

export default {
  name: "signin",
  components: {
    Formulario,
    PWAPrompt,
    FooterComponent,
  },
  data() {
    return {
      isLogin: true,
      promoCode: "",
      year: new Date().getFullYear(),
      isMobile: this.$isMobile,
      photo: "/img/banner-tus-$20-facilito.webp",
    };
  },
  beforeMount() {},
  mounted() {
    this.checkDevice();
    document.body.classList.remove("dark-version");
    this.shown = !(
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    );

    this.$eventBus.on("ocultarPWA", this.handleDismissPrompt);

    document.title = "XtrimPro - Inicio de sesión";
  },
  methods: {
    checkDevice() {
      const userAgent = navigator.userAgent;
      this.isMac = /Macintosh|Mac OS/.test(userAgent) && !this.isIOS;
      this.isWindows = /Windows NT/.test(userAgent);
      this.isLinux = /Linux/.test(userAgent) && !/Android/.test(userAgent);
      this.isFirefox = /Firefox/.test(userAgent);
      const isDesktop = this.isMac || this.isWindows || this.isLinux;
      if (isDesktop && this.isFirefox) {
        this.shown = false;
      }
    },
    handleDismissPrompt() {
      this.shown = false; // Hide the prompt when dismissed
    },
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.container-fluid {
  padding: 0px;
  margin: 0px;
}

.loginCard {
  /* @media (max-width: 1199px) {
    padding: 45px 30px;
    border-radius: 15px;
    background: #fbfbfb;
    box-shadow: 0px 0px 15px 15px #e0e0e0;
    margin: 30px 0;
  } */
  @media (max-width: 1199px) {
    padding: 0 15px;
    border-radius: 15px;
    background: transparent;
    /* background: #fbfbfb; */
    /* box-shadow: 0px 0px 15px 15px #520454; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
}

.container {
  width: 91.666667%; /* Equivalent to col-11 */
  display: none;
  @media (min-width: 576px) {
    width: 100%; /* Equivalent to col-sm-12 */
  }
  @media (min-width: 768px) {
    width: 100%; /* Equivalent to col-md-12 */
  }
  @media (min-width: 992px) {
    width: 100%; /* Equivalent to col-lg-12 */
  }
  @media (min-width: 1200px) {
    width: 66.66666666%; /* Equivalent to col-xl-4 */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    text-align: center; /* Centers text */
    align-items: center; /* Centers content vertically */
  }
}

.responsiveContainer {
  width: 100%; /* Equivalent to col-11 */
  height: fit-content;
  min-height: 100vh;
  /* Responsive widths */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Centers content horizontally */
  text-align: center; /* Centers text */
  align-items: center; /* Centers content vertically */
  background: radial-gradient(circle at top left, #cbbdfe, transparent 50%),
    radial-gradient(circle at bottom right, #cbbdfe, transparent 40%), white;

  @media (min-width: 576px) {
    width: 100%; /* Equivalent to col-sm-12 */
  }
  @media (min-width: 768px) {
    width: 100%; /* Equivalent to col-md-12 */
  }
  @media (min-width: 992px) {
    width: 100%; /* Equivalent to col-lg-12 */
  }
  @media (min-width: 1200px) {
    width: 33.33%; /* Equivalent to col-xl-4 */
  }
}

.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* min-height: calc(var(--vh, 1vh) * 100); */
  height: 100vh;
  margin: 0px;
}

.headerContainer {
  position: relative; /* Ensures child elements are positioned relative to this container */
  height: 105px; /* Full viewport height */
  background-image: url("../../src/assets/img/Xtrim/register-background.png"); /* Background image */
  background-size: cover; /* Scales the background to cover the container */
  background-position: center; /* Centers the background */
  background-repeat: no-repeat; /* Prevents repeating the background image */
  width: 100%;
  @media (max-width: 1199px) {
    width: 100vw;
  }
}

.headerContainerDesktop {
  display: none;
  position: relative; /* Ensures child elements are positioned relative to this container */
  width: 100vw; /* Full viewport width */
  height: 105px; /* Full viewport height */
  @media (min-width: 1200px) {
    display: block;
  }
}

.headerLogo {
  position: absolute; /* Position the logo relative to the container */
  top: 50%; /* Move it down 50% of the container's height */
  left: 50%; /* Move it right 50% of the container's width */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust to center by offsetting its own dimensions */
  width: 150px; /* Adjust logo size */
  height: auto; /* Maintain aspect ratio */
}
</style>
