<template>
  <div>
    <PWAPrompt v-if="shown" :install-event="installEvent" />
    <div class="row">
      <router-link
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        :to="{ name: 'Perfil' }"
      >
        <div
          class="card2"
          style="
            background: linear-gradient(
              68deg,
              rgb(150 19 171) 0%,
              rgb(185 33 191) 100%
            );
            border: 3px solid #f685ed;
          "
        >
          <div class="card-body">
            <div class="row">
              <div
                class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                align="center"
              >
                <v-lazy-image
                  :src="photo"
                  class="rounded-circle"
                  style="background: white; width: 40px"
                />
              </div>

              <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <p
                  style="
                    margin: 0px;
                    font-size: 12px;
                    text-transform: capitalize;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: white;
                    font-weight: 500;
                  "
                >
                  {{ names + " " + lastnames }}
                </p>
                <p
                  style="
                    text-align: left;
                    font-size: 11px;
                    margin: 0px;
                    color: white;
                    font-weight: 500;
                  "
                >
                  Editar perfil
                </p>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <!-- <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body">
            <div class="d-flex">
              <h6
                class="mb-0"
                style="font-size: 14px"
                :class="this.$store.state.isRTL ? 'ms-2' : ''"
              >
                Modo {{ modo }} activado
              </h6>
              <div class="form-check form-switch ps-0 ms-auto my-auto">
                <input
                  class="form-check-input mt-1 ms-auto"
                  type="checkbox"
                  :checked="this.$store.state.darkMode"
                  @click="setDarkMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <router-link
        :to="{ name: 'descripcion' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        <div class="card2 mobileCard">
          <div class="card-body" style="padding: 10px !important">
            <p
              style="
                margin: 0px;
                text-align: center;
                color: white;
                font-weight: 700;
              "
            >
              <i style="font-size: 16px; color: white" class="fas fa-list"></i
              ><br />Políticas XtrimPro
            </p>
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'planes' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        <div class="card2 mobileCard">
          <div class="card-body" style="padding: 10px !important">
            <p
              style="
                margin: 0px;
                text-align: center;
                color: white;
                font-weight: 700;
              "
            >
              <i style="font-size: 16px; color: white" class="fas fa-wifi"></i
              ><br />Planes Disponibles
            </p>
          </div>
        </div>
      </router-link>
      <button
        v-if="isMobile && !insidePWA"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-top: 10px; margin-bottom: 10px"
        type="button"
        @click="togglePWA"
      >
        <div class="card2 mobileCard">
          <div class="card-body" style="padding: 10px !important">
            <p
              style="
                margin: 0px;
                text-align: center;
                color: white;
                font-weight: 700;
              "
            >
              <i
                style="font-size: 16px; color: white"
                class="fa-solid fa-mobile-screen"
              ></i>
              <br />¡Descarga la App!
            </p>
          </div>
        </div>
      </button>
      <div
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 1rem;
          text-align: center;
        "
      >
        <button
          @click="exit()"
          class="btn btn-yellow-normal btn-sm"
          type="button"
          style="width: 100%"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import myMixin from "../mixin.js";
import { mapActions, mapState } from "vuex";
import PWAPrompt from "../views/PWAPrompt.vue";

export default {
  name: "Mas",
  components: {
    PWAPrompt,
  },
  data() {
    const { logout } = myMixin();

    let datos = this.$store.state.user;

    let photo = "";

    if (datos.photo === "") {
      photo = "/img/user.svg";
    } else {
      photo = datos.photo;
    }

    return {
      userId: datos.userId,
      userLoginId: datos.userLoginId,
      isMobile: this.$isMobile,
      names: datos.names,
      lastnames: datos.lastnames,
      tokenAuth: this.$store.state.tokenAuth,
      logout: logout,
      modo: "",
      photo: photo,
      insidePWA: false,
      installEvent: false,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  computed: {
    ...mapState(["shown"]),
  },
  methods: {
    ...mapActions(["togglePWA"]),
    exit() {
      this.$eventBus.emit("clearOverlaySidebar", true);

      this.logout(this.userLoginId, this.tokenAuth, true);
    },
  },
  mounted() {
    if (this.$store.state.darkMode) {
      this.modo = "noche";
    } else {
      this.modo = "día";
    }
    // Ensure media query exists before using it
    const isStandalone = window.matchMedia
      ? window.matchMedia("(display-mode: standalone)").matches
      : false;

    // Ensure window.navigator.standalone is always a boolean
    this.insidePWA = isStandalone || !!window.navigator.standalone;

    console.log("insidePWA", this.insidePWA);
  },
};
</script>

<style scoped>
.mobileCard {
  background-color: #820e82;
  border: 3px solid #f685ed;
}
</style>

<!-- <router-link
        :to="{ name: 'recursos' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-top: 10px; margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i
                style="font-size: 16px"
                class="fa fa-book-open text-success"
              ></i
              ><br />Recursos
            </p>
          </div>
        </div>
      </router-link> -->

<!-- <router-link
        :to="{ name: 'referidos' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i style="font-size: 16px" class="fa fa-users text-success"></i
              ><br />Equipo
            </p>
          </div>
        </div>
      </router-link> -->

<!-- <router-link
        :to="{ name: 'ventas' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i
                style="font-size: 16px"
                class="fa fa-shopping-cart text-success"
              ></i
              ><br />Mis Referidos
            </p>
          </div>
        </div>
      </router-link> -->

<!-- <router-link
        :to="{ name: 'estadisticas' }"
        v-if="isMobile"
        class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        style="margin-bottom: 10px"
      >
        <div class="card2">
          <div class="card-body" style="padding: 10px !important">
            <p style="margin: 0px; text-align: center">
              <i style="font-size: 16px" class="fas fa-eye text-success"></i
              ><br />Visión 360
            </p>
          </div>
        </div>
      </router-link> -->
