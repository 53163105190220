<template>
  <div
    class="card copyLinkCard"
    :class="{ card2: isMobile, margin_card: !isMobile }"
    style="background: #5e005f"
  >
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
      "
    >
      <div style="width: 100%">
        <h6
          style="
            font-size: 14px;
            color: white;
            font-weight: bold;
            margin-bottom: 12px;
            line-height: 15px;
            text-align: center;
          "
        >
          ¡Comparte tu link y gana tus próximos $20!
        </h6>
      </div>

      <div style="width: 100%">
        <div class="input-group justify-content-center">
          <input
            style="background-color: #e9ecef; color: black"
            type="text"
            required
            :readonly="true"
            v-model="link3"
            class="form-control col-lg-6 text-center"
            id="link3"
            ref="link3"
            aria-describedby="button-addon2"
          />
        </div>
      </div>
      <button class="pinkPurpleText" @click="copyInputValue('link3')">
        Copiar Link<i
          class="fa fa-clone"
          aria-hidden="true"
          style="margin-right: 5px"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["promoCode", "isMobile"],
  data() {
    return {
      link3: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.link3 = "www." + window.location.host + "/venta/" + this.promoCode;
  },
  methods: {
    copyInputValue(model) {
      const inputElement = document.getElementById(model);
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.$toast.success("Link Copiado exitosamente", {
        position: "top-right",
        max: 10,
      });
    },
  },
};
</script>

<style scoped>
.copyLinkCard {
  background-color: #820e82 !important;
  border: 3px solid #f685ed;
  border-radius: 28px !important;
  /* box-shadow: 0px 0px 15px 4px rgb(220 82 212 / 43%); */
}

.pinkPurpleText {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Ubuntu;
  width: fit-content;
  border-radius: 16px;
  gap: 8px;
  margin: 8px 0 0 0;
  font-weight: 900;
  /* box-shadow: 0px 2px 10px 2px #bc49be48; */
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pinkPurpleText:hover {
  background: linear-gradient(
    68deg,
    rgb(226, 80, 232) 0%,
    rgba(170, 50, 189, 1) 100%
  );
}
</style>
