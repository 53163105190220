<template>
  <VueFinalModal
    v-model="isOpen"
    @close="closeModal"
    :esc-to-close="false"
    class="modal-container"
    content-class="modal-content4"
  >
    <div style="padding: 10px">
      <div style="display: flex; justify-content: space-between">
        <p style="font-size: 20px; font-weight: bold">Agregar promoción</p>
        <div @click="closeModal">
          <i class="fas fa-times-square close_modal" style="color: grey"></i>
        </div>
      </div>

      <input
        type="file"
        ref="fileInput"
        :key="fileInputKey"
        style="display: none"
        @change="handleFileSelect"
        accept="image/*"
      />
      <div v-if="!url" class="upload-container">
        <div
          class="drop-zone"
          @click="triggerFileUpload"
          @dragover.prevent
          @dragenter.prevent="dragEnter"
          @dragleave.prevent="dragLeave"
          @drop.prevent="handleDrop"
          :class="{
            'drop-zone--over': isDragging,
            'drop-zone--loading': isUploading,
          }"
        >
          <div class="drop-zone__content">
            <i v-if="!isUploading" class="fas fa-cloud-upload"></i>
            <i v-else class="fas fa-spinner fa-spin"></i>
            <p>
              {{
                isUploading
                  ? "Subiendo imagen..."
                  : "Arrastra y suelta tu imagen aquí o haz clic para seleccionar"
              }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="preview-container">
        <div class="image-preview">
          <img :src="url" alt="Banner" />
          <div v-if="creatingPromotion" class="loading-overlay">
            <i class="fas fa-spinner fa-spin"></i>
            <p>Guardando promoción...</p>
          </div>
          <button
            @click="deleteImage"
            class="delete-button"
            :disabled="creatingPromotion"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <button
          @click="addPromotion"
          :disabled="isUploading || creatingPromotion"
          class="btn btn-success mt-3"
        >
          <i v-if="creatingPromotion" class="fas fa-spinner fa-spin me-2"></i>
          {{ creatingPromotion ? "Guardando..." : "Agregar" }}
        </button>
      </div>
    </div>
  </VueFinalModal>
  <div class="card card-body">
    <div style="display: flex; justify-content: space-between">
      <p style="font-size: 20px; font-weight: bold">Promociones</p>
      <button class="btn btn-primary" @click="openModal">
        Agregar Promoción <i class="fas fa-plus"></i>
      </button>
    </div>
    <div style="display: flex; justify-content: space-between">
      <div v-if="loading" class="w-100 text-center py-5">
        <i class="fas fa-spinner fa-spin me-2"></i>
        Cargando promociones...
      </div>
      <ul v-else style="width: 100%">
        <li
          v-for="promotion in promotions"
          :key="promotion.id"
          style="
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            width: 100%;
          "
        >
          <div style="display: flex; align-items: center; gap: 10px">
            <img :src="promotion.url" alt="Promoción" class="promotion-image" />
            <div>
              <p>
                Fecha de creación:
                <b>
                  {{
                    new Date(
                      promotion.createdAt._seconds * 1000,
                    ).toLocaleString("es-ES", {
                      timeZone: "America/Guayaquil",
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </b>
              </p>

              <p v-if="promotion.updatedAt">
                Fecha de actualización:
                <b>
                  {{
                    new Date(
                      promotion.updatedAt._seconds * 1000,
                    ).toLocaleString("es-ES", {
                      timeZone: "America/Guayaquil",
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}
                </b>
              </p>
            </div>
          </div>

          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              :id="'promotionSwitch' + promotion.id"
              v-model="promotion.isActive"
              @change="togglePromotion(promotion)"
              :disabled="togglingPromotions.includes(promotion.id)"
            />
            <label
              :for="'promotionSwitch' + promotion.id"
              class="form-check-label d-flex align-items-center"
            >
              <i
                v-if="togglingPromotions.includes(promotion.id)"
                class="fas fa-spinner fa-spin me-2"
              ></i>
              {{ promotion.isActive ? "Activa" : "Inactiva" }}
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";

export default {
  name: "Promociones",
  components: {
    VueFinalModal,
  },
  data() {
    return {
      isOpen: false,
      selectedFile: null,
      creatingPromotion: false,
      url: null,
      isUploading: false,
      currentImageRef: null,
      fileInputKey: 0,
      isDragging: false,
      promotions: [],
      togglingPromotions: [],
      loading: true,
    };
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      this.selectedFile = null;
      this.url = null;
      this.currentImageRef = null;
      this.$refs.fileInput.value = "";
      this.fileInputKey += 1;
    },

    openModal() {
      this.isOpen = true;
    },

    async handleFileSelect(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        await this.uploadToFirebase();
      }
    },

    async uploadToFirebase() {
      this.isUploading = true;
      try {
        const currentUser = this.$appFirebase.auth().currentUser;
        if (!currentUser) {
          throw new Error("Usuario no autenticado");
        }

        const storageRef = this.$appFirebase
          .storage()
          .ref(`banners/${this.selectedFile.name}`);

        const uploadBannerTask = await storageRef.put(this.selectedFile);
        this.currentImageRef = storageRef;

        const downloadURL = await uploadBannerTask.ref.getDownloadURL();
        this.url = downloadURL;
      } catch (err) {
        console.error("Error uploading file:", err);
        alert(err.message || "Error al subir la imagen");
      } finally {
        this.isUploading = false;
      }
    },

    async deleteImage() {
      try {
        if (this.currentImageRef) {
          await this.currentImageRef.delete();
          this.url = null;
          this.selectedFile = null;
          this.currentImageRef = null;
          this.$refs.fileInput.value = "";
          this.fileInputKey += 1;
        }
      } catch (err) {
        console.error("Error deleting image:", err);
        alert("Error al eliminar la imagen");
      }
    },

    addPromotion() {
      if (!this.url) {
        console.error("No image URL available");
        return;
      }

      this.creatingPromotion = true;
      this.$https
        .post("/administration/createPromotion", {
          url: this.url,
          tokenAuth: this.$store.state.tokenAuth,
          userId: this.$store.state.user.userId,
          role: this.$store.state.user.role,
        })
        .then(() => {
          this.closeModal();
          this.fetchPromotions();
        })
        .catch((err) => {
          console.error("Error creating promotion:", err);
        })
        .finally(() => {
          this.creatingPromotion = false;
        });
    },

    triggerFileUpload() {
      this.$refs.fileInput.click();
    },

    dragEnter() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    handleDrop(e) {
      this.isDragging = false;
      const file = e.dataTransfer.files[0];
      if (file && file.type.startsWith("image/")) {
        this.selectedFile = file;
        this.uploadToFirebase();
      } else {
        alert("Por favor, selecciona un archivo de imagen válido");
      }
    },

    async togglePromotion(promotion) {
      try {
        this.togglingPromotions.push(promotion.id);

        await this.$https.post("/administration/updatePromotion", {
          promotionId: promotion.id,
          isActive: promotion.isActive,
          tokenAuth: this.$store.state.tokenAuth,
          userId: this.$store.state.user.userId,
          role: this.$store.state.user.role,
        });
      } catch (err) {
        console.error("Error updating promotion:", err);
        promotion.isActive = !promotion.isActive;
        alert("Error al actualizar la promoción");
      } finally {
        const index = this.togglingPromotions.indexOf(promotion.id);
        if (index > -1) {
          this.togglingPromotions.splice(index, 1);
        }
      }
    },
    async fetchPromotions() {
      this.$https
        .post("/administration/fetchPromotions", {
          tokenAuth: this.$store.state.tokenAuth,
          userId: this.$store.state.user.userId,
          role: this.$store.state.user.role,
        })
        .then((res) => {
          this.promotions = res.data.banners;
          this.loading = false;
        })
        .catch((err) => {
          console.error("Error fetching promotions:", err);
        });
    },
  },
  mounted() {
    this.fetchPromotions();
  },
};
</script>

<style scoped>
h1 {
  color: #000;
}

.image-preview {
  margin: 15px 0;
  position: relative;
  display: inline-block;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-preview img {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 0;
  display: block;
  border-radius: 12px;
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #dc3545;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  z-index: 1;
}

.delete-button:hover {
  background-color: #c82333;
  transform: scale(1.1);
}

.delete-button i {
  font-size: 12px;
}

.btn {
  margin: 5px;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
}

.btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.drop-zone {
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  margin-bottom: 15px;
}

.drop-zone:hover {
  border-color: #7d0c7e;
  background-color: #fdf5fd;
}

.drop-zone--over {
  border-color: #7d0c7e;
  background-color: #faf0fa;
}

.drop-zone--loading {
  border-color: #7d0c7e;
  background-color: #faf0fa;
  pointer-events: none;
  opacity: 0.8;
}

.drop-zone__content {
  text-align: center;
  color: #6c757d;
}

.drop-zone__content i {
  font-size: 3em;
  margin-bottom: 10px;
  color: #7d0c7e;
}

.drop-zone__content p {
  margin: 0;
  font-size: 1.1em;
}

.upload-container,
.preview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.preview-container {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 1rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 12px;
}

.loading-overlay i {
  font-size: 2em;
  margin-bottom: 10px;
}

.loading-overlay p {
  margin: 0;
  font-size: 1em;
}

.me-2 {
  margin-right: 0.5rem;
}

.fa-spin {
  animation: fa-spin 1s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.promotion-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
