<template>
  <div>
    <div class="row" v-if="loading">
      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        align="center"
        style="min-height: calc(var(--vh, 1vh) * 80); display: flex"
      >
        <div style="margin: auto; text-align: center">
          <i style="font-size: 50px" class="fas fa-spinner fa-pulse"></i>

          <p style="font-size: 16px; font-weight: bold">Comprobando usuario</p>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card" style="border-radius: 12px">
          <ul
            class="nav nav-pills"
            id="pills-tab"
            role="tablist"
            style="border-radius: 12px"
          >
            <li class="nav-item" role="presentation">
              <button
                @click="ResumenLoad"
                class="nav-link2"
                :class="activoPanel === 5 ? 'active' : ''"
                id="pills-ventas-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ventas"
                type="button"
                role="tab"
                aria-controls="pills-ventas"
                aria-selected="true"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-chart-simple text-success"
                ></i>
                Resumen
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                @click="VentasLoad"
                class="nav-link2"
                :class="activoPanel === 0 ? 'active' : ''"
                id="pills-ventas-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ventas"
                type="button"
                role="tab"
                aria-controls="pills-ventas"
                aria-selected="true"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-shopping-cart text-success"
                ></i>
                Ventas
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="userRole === 'admin'"
            >
              <button
                @click="RetirosLoad"
                class="nav-link2"
                :class="activoPanel === 1 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-money-bill text-success"
                ></i>
                Retiros
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                @click="VentasInstaladasLoad"
                class="nav-link2"
                :class="activoPanel === 2 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-money-bill text-success"
                ></i>
                Ventas Instaladas
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="userRole === 'admin'"
            >
              <button
                @click="RecursosLoad"
                class="nav-link2"
                :class="activoPanel === 3 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-folder-tree text-success"
                ></i>
                Recursos
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                @click="UsuariosLoad"
                class="nav-link2"
                :class="activoPanel === 4 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-users text-success"
                ></i>
                Usuarios
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="userRole === 'admin'"
            >
              <button
                @click="PromocionesLoad"
                class="nav-link2"
                :class="activoPanel === 6 ? 'active' : ''"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                <i
                  style="font-size: 14px; margin-right: 5px"
                  class="fa fa-gift text-success"
                ></i>
                Promociones
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
        style="margin-top: 15px"
      >
        <div class="card">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade"
              :class="activoPanel === 0 ? 'show active' : ''"
              id="pills-ventas"
              role="tabpanel"
              aria-labelledby="pills-ventas-tab"
              tabindex="0"
            >
              <listado-ventas
                v-if="ifVentas"
                :key="componentKeyVentas"
                :user-id="userId"
                :token-auth="tokenAuth"
                :role="role"
                :user-role="userRole"
              />
            </div>
            <div
              class="tab-pane fade"
              :class="activoPanel === 1 ? 'show active' : ''"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <listado-retiros
                v-if="ifRetiros"
                :key="componentKeyRetiros"
                :user-id="userId"
                :token-auth="tokenAuth"
                :role="role"
              />
            </div>
            <div
              class="tab-pane fade"
              :class="activoPanel === 2 ? 'show active' : ''"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <listado-ventas-instaladas
                v-if="ifVentasInstaladas"
                :key="componentKeyInstaladas"
                :user-id="userId"
                :token-auth="tokenAuth"
                :role="role"
              />
            </div>
            <div
              class="tab-pane fade"
              :class="activoPanel === 3 ? 'show active' : ''"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <recursos-admin v-if="ifRecursos" />
            </div>
            <div
              class="tab-pane fade"
              :class="activoPanel === 4 ? 'show active' : ''"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <listado-usuarios :key="componentKeyUsuarios" v-if="ifUsuarios" />
            </div>
            <div
              class="tab-pane fade"
              :class="activoPanel === 6 ? 'show active' : ''"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <promociones
                :key="componentKeyPromociones"
                v-if="ifPromociones"
              />
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="activoPanel === 5 ? 'show active' : ''"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabindex="0"
        >
          <resumen :key="componentKeyResumen" v-if="ifResumen" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import "bootstrap"; //no eliminar, este le da el evento al nav pills
import ListadoVentas from "./ListadoVentas.vue";
import ListadoRetiros from "./ListadoRetiros.vue";
import ListadoVentasInstaladas from "./ListadoVentasInstaladas.vue";
import RecursosAdmin from "../Administracion/RecursosAdmin.vue";
import ListadoUsuarios from "../Administracion/ListadoUsuarios.vue";
import Resumen from "./Resumen.vue";
import Promociones from "./Promociones.vue";

export default {
  components: {
    ListadoVentas,
    ListadoRetiros,
    ListadoVentasInstaladas,
    RecursosAdmin,
    ListadoUsuarios,
    Resumen,
    Promociones,
  },
  data() {
    let datos = this.$store.state.user;

    return {
      isMobile: this.$isMobile,
      userId: datos.userId,
      tokenAuth: this.$store.state.tokenAuth,
      role: datos.role,
      loading: false,
      componentKey: 0,
      componentKeyInstaladas: 0,
      activoPanel: 5,
      componentKeyVentas: 100,
      ifVentas: false,
      ifVentasInstaladas: false,
      ifRetiros: false,
      ifRecursos: false,
      ifUsuarios: false,
      ifResumen: false,
      ifPromociones: false,
      componentKeyRetiros: 200,
      componentKeyUsuarios: 300,
      componentKeyResumen: 400,
      componentKeyPromociones: 500,
      userRole: "",
    };
  },
  watch: {
    $route(to, from) {
      this.updatePanel(to.hash.substring(1));
    },
  },
  created() {},
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  mounted() {
    this.$eventBus.on("reiniciarListadoVentasAdmin", () => {
      this.componentKeyVentas += 1;
    });

    this.$eventBus.on("reiniciarListadoRetirosAdmin", () => {
      this.componentKeyRetiros += 1;
    });

    this.$eventBus.on("reiniciarListadoVentasInstaladasAdmin", () => {
      this.componentKeyInstaladas += 1;
    });

    this.$eventBus.on("reiniciarListadoUsuariosAdmin", () => {
      this.componentKeyUsuarios += 1;
    });

    this.$eventBus.on("reiniciarResumenAdmin", () => {
      this.componentKeyResumen += 1;
    });

    this.$eventBus.on("reiniciarPromocionesAdmin", () => {
      this.componentKeyPromociones += 1;
    });

    this.$eventBus.on("reiniciarAdmin", () => {
      this.componentKey += 1;
    });

    document.title = "XTRIMPRO - Administración";

    this.init();
    this.ResumenLoad();
    this.getAccess();
  },
  methods: {
    init() {},

    VentasLoad() {
      this.updatePanel("ventas");
    },
    RetirosLoad() {
      this.updatePanel("retiros");
    },
    VentasInstaladasLoad() {
      this.updatePanel("instaladas");
    },
    RecursosLoad() {
      this.updatePanel("recursos");
    },
    UsuariosLoad() {
      this.updatePanel("usuarios");
    },
    ResumenLoad() {
      this.updatePanel("resumen");
    },
    PromocionesLoad() {
      this.updatePanel("promociones");
    },
    updatePanel(hash) {
      switch (hash) {
        case "ventas":
          this.activoPanel = 0;
          this.ifVentas = true;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = false;
          this.ifPromociones = false;
          break;
        case "retiros":
          this.activoPanel = 1;
          this.ifVentas = false;
          this.ifRetiros = true;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = false;
          this.ifPromociones = false;
          break;
        case "instaladas":
          this.activoPanel = 2;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = true;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = false;
          this.ifPromociones = false;
          break;
        case "recursos":
          this.activoPanel = 3;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = true;
          this.ifUsuarios = false;
          this.ifResumen = false;
          this.ifPromociones = false;
          break;
        case "usuarios":
          this.activoPanel = 4;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = true;
          this.ifResumen = false;
          this.ifPromociones = false;
          break;
        case "resumen":
          this.activoPanel = 5;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = true;
          this.ifPromociones = false;
          break;
        case "promociones":
          this.activoPanel = 6;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = false;
          this.ifPromociones = true;
          break;
        default:
          this.activoPanel = 5;
          this.ifVentas = false;
          this.ifRetiros = false;
          this.ifVentasInstaladas = false;
          this.ifRecursos = false;
          this.ifUsuarios = false;
          this.ifResumen = true;
          this.ifPromociones = false;
      }
    },
    getAccess() {
      this.loading = true;
      this.$https
        .post("/access/accessAdminPanel", {
          tokenAuth: this.$store.state.tokenAuth, // Assuming the user object contains the token
          role: this.$store.state.user.role, // Role should be passed for comparison
          userLoginId: this.$store.state.user.userLoginId, // Assuming userLoginId is available
        })
        .then((response) => {
          this.userRole = response.data.role;
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
