import { createStore } from "vuex";
import createPersistedStatePlugin from "./persist";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    user: null,
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    tokenAuth: null,
    bankData: null,
    shown: false,
    bannerShown: false,
  },
  mutations: {
    resetUserData(state) {
      state.user = null; // or an empty object
      state.tokenAuth = null; // Clear token
    },

    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },

    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (state.isPinned) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      }
    },

    sidebarType(state, payload) {
      state.sidebarType = payload;
    },

    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },

    setShown(state, value) {
      state.shown = value;
    },

    setUser(state, user) {
      state.user = user;
      state.bannerShown = false; // Reset banner state when user logs in
    },

    // Mutation to set layout state
    setLayout(state, layout) {
      state.layout = layout;
    },

    // Mutation to set hideConfigButton state
    setHideConfigButton(state, value) {
      state.hideConfigButton = value;
    },

    // Mutation to set isPinned state
    setIsPinned(state, value) {
      state.isPinned = value;
    },

    // Mutation to set showConfig state
    setShowConfig(state, value) {
      state.showConfig = value;
    },

    // Mutation to set sidebarType state
    setSidebarType(state, value) {
      state.sidebarType = value;
    },

    // Mutation to set isRTL state
    setIsRTL(state, value) {
      state.isRTL = value;
    },

    // Mutation to set mcolor state
    setMcolor(state, value) {
      state.mcolor = value;
    },

    // Mutation to set darkMode state
    setDarkMode(state, value) {
      state.darkMode = value;
    },

    // Mutation to set isNavFixed state
    setIsNavFixed(state, value) {
      state.isNavFixed = value;
    },

    // Mutation to set isAbsolute state
    setIsAbsolute(state, value) {
      state.isAbsolute = value;
    },

    // Mutation to set showNavs state
    setShowNavs(state, value) {
      state.showNavs = value;
    },

    // Mutation to set showSidenav state
    setShowSidenav(state, value) {
      state.showSidenav = value;
    },

    // Mutation to set showNavbar state
    setShowNavbar(state, value) {
      state.showNavbar = value;
    },

    // Mutation to set showFooter state
    setShowFooter(state, value) {
      state.showFooter = value;
    },

    // Mutation to set showMain state
    setShowMain(state, value) {
      state.showMain = value;
    },

    // Mutation to set tokenAuth state
    setTokenAuth(state, value) {
      state.tokenAuth = value;
    },
    // Mutation to set bankData state
    setBankData(state, value) {
      state.bankData = value;
    },

    // Simplified banner mutations
    setBannerShown(state) {
      state.bannerShown = true;
    },
    resetBannerShown(state) {
      state.bannerShown = false;
    },

    clearSelectedState(
      state,
      inclusions = ["user", "tokenAuth", "bankData", "bannerShown"],
    ) {
      // Clear only the specified Vuex states
      inclusions.forEach((key) => {
        if (key in state) {
          state[key] = null;
        }
      });

      // Clear only the specified keys from localStorage
      inclusions.forEach((key) => {
        if (localStorage.getItem(key) !== null) {
          localStorage.removeItem(key);
        }
      });
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    updateUser({ commit }, user) {
      commit("setUser", user);
    },

    // Action to update layout
    updateLayout({ commit }, layout) {
      commit("setLayout", layout);
    },

    // Action to update hideConfigButton
    updateHideConfigButton({ commit }, value) {
      commit("setHideConfigButton", value);
    },

    // Action to update isPinned
    updateIsPinned({ commit }, value) {
      commit("setIsPinned", value);
    },

    // Action to update showConfig
    updateShowConfig({ commit }, value) {
      commit("setShowConfig", value);
    },

    // Action to update sidebarType
    updateSidebarType({ commit }, value) {
      commit("setSidebarType", value);
    },

    // Action to update isRTL
    updateIsRTL({ commit }, value) {
      commit("setIsRTL", value);
    },

    // Action to update mcolor
    updateMcolor({ commit }, value) {
      commit("setMcolor", value);
    },

    // Action to update darkMode
    updateDarkMode({ commit }, value) {
      commit("setDarkMode", value);
    },

    // Action to update isNavFixed
    updateIsNavFixed({ commit }, value) {
      commit("setIsNavFixed", value);
    },

    // Action to update isAbsolute
    updateIsAbsolute({ commit }, value) {
      commit("setIsAbsolute", value);
    },

    // Action to update showNavs
    updateShowNavs({ commit }, value) {
      commit("setShowNavs", value);
    },

    // Action to update showSidenav
    updateShowSidenav({ commit }, value) {
      commit("setShowSidenav", value);
    },

    // Action to update showNavbar
    updateShowNavbar({ commit }, value) {
      commit("setShowNavbar", value);
    },

    // Action to update showFooter
    updateShowFooter({ commit }, value) {
      commit("setShowFooter", value);
    },

    // Action to update showMain
    updateShowMain({ commit }, value) {
      commit("setShowMain", value);
    },

    // Action to update tokenAuth
    updateTokenAuth({ commit }, value) {
      commit("setTokenAuth", value);
    },

    // Action to update bankData
    updateBankData({ commit }, value) {
      commit("setBankData", value);
    },

    togglePWA({ state, commit }) {
      console.log("Presiona togglePWA", state.shown);
      commit("setShown", !state.shown);
    },

    // Simplified banner actions
    markBannerAsShown({ commit }) {
      commit("setBannerShown");
    },
    resetBanner({ commit }) {
      commit("resetBannerShown");
    },

    clearAllData({ commit, state }) {
      // Commit the mutation to clear Vuex state
      commit("clearSelectedState");

      // Clear all keys from localStorage
      Object.keys(state).forEach((key) => {
        localStorage.removeItem(key);
      });
      // Clear sessionStorage (if applicable)
      Object.keys(sessionStorage).forEach((key) => {
        sessionStorage.removeItem(key);
      });

      // Optional: Clear cookies (if you're storing anything in cookies)
      // document.cookie.split(";").forEach(function (c) {
      //   document.cookie = c.trim().split("=")[0] + "=;expires=" + new Date(0).toUTCString() + ";path=/";
      // });

      // Optionally reset user data in Vuex
      commit("resetUserData");
    },
  },
  getters: {},
  plugins: [
    createPersistedStatePlugin(), // Persist all keys automatically
  ],
});
