<template>
  <div class="card container" style="margin-bottom: 10px">
    <div
      v-if="!loadingBalance"
      @click="refresh"
      style="cursor: pointer; position: absolute; top: 10px; right: 15px"
    >
      <i style="font-size: 6px" class="fas fa-sync text-sm opacity-10"></i>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      "
    >
      <div
        style="
          margin-bottom: 10px;
          justify-content: center;
          display: flex;
          width: 100%;
        "
      >
        <h5
          class="card-title mb-0 text-uppercase"
          style="
            font-size: 12px;
            background-color: #7d0c7e;
            margin: 0;
            padding: 4px 8px;
            border-radius: 8px;
            color: white;
            font-weight: 900;
          "
        >
          Mi Saldo
        </h5>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        "
      >
        <div style="width: auto">
          <div
            v-if="loadingBalance"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto;
            "
          >
            <Skeletor
              :shimmer="true"
              height="38"
              width="75"
              style="margin-bottom: 0px"
            />
          </div>
          <div
            v-else
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              width: fit-content;
            "
          >
            <img
              src="../../assets/img/Xtrim/billete.png"
              style="position: absolute; left: -11px; height: 24px"
            />

            <p class="font-weight-bolder totalBalanceCard">
              <span style="font-size: 14px">$</span>{{ balance.toFixed(2) }}
            </p>
          </div>
        </div>

        <div style="display: flex">
          <button
            @click="withdraw()"
            class="btn btn-sm btn-withdraw col-10"
            type="button"
          >
            Retirar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: ["userId", "tokenAuth", "balanceTotal", "loadingBalance"],
  data() {
    return {
      balance: this.balanceTotal ?? 0,
    };
  },
  computed: {
    loadingBalance() {
      return this.loadingBalance;
    },
    balance() {
      return this.balanceTotal;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    withdraw() {
      this.$eventBus.emit("showModalRetiro");
    },

    refresh() {
      this.$eventBus.emit("reiniciarSaldos");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  background: rgb(247, 152, 242);
  background: linear-gradient(
    68deg,
    rgba(247, 152, 242, 1) 0%,
    rgba(250, 212, 248, 1) 100%
  );
  border: 2px solid #f65bf0;
  border-radius: 24px !important;
  padding: 10px;
  height: 90%;
}

.btn-withdraw {
  color: #7d0c7e;
  font-size: 16px !important;
  padding: 3px 10px !important;
  text-shadow:
    2px 2px 0px white,
    -2px -2px 0px white,
    2px -2px 0px white,
    -2px 2px 0px white;
  background: rgb(252, 217, 0) !important;
  background: linear-gradient(
    68deg,
    rgba(252, 217, 0, 1) 0%,
    rgba(253, 245, 4, 1) 100%
  ) !important;
  width: 100%;
  margin: auto;
  font-weight: 900;
  font-family: Ubuntu;
}
.btn-withdraw:hover {
  color: #7d0c7e !important;
  outline: none;
}
.btn-withdraw:focus {
  color: #7d0c7e !important;
  outline: none;
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.totalBalanceCard {
  margin: 0px;
  background: rgb(168, 53, 153);
  background: linear-gradient(
    68deg,
    rgba(168, 53, 153, 1) 0%,
    rgba(222, 79, 217, 1) 100%
  );
  color: white;
  border: 2px solid #f65bf0;
  border-radius: 16px;
  font-size: 22px !important;
  padding: 3px 10px;
}

@media (min-width: 1200px) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .totalBalanceCard {
    font-size: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
@media (min-width: 100px) and (max-width: 319px) {
  .totalBalanceCard {
    font-size: 34px;
  }
}
</style>
