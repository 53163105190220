<template>
  <div>
    <h1 class="mb-0">Planes Disponibles</h1>
    <div class="carouselContainer">
      <Skeletor height="100%" width="100%" v-if="loading" />
      <Carousel
        v-else
        breakpoint-mode="carousel"
        :breakpoints="{
          700: {
            itemsToShow: 1.4,
            snapAlign: 'start',
            // wrapAround: true,
          },
          800: {
            itemsToShow: 1.6,
            snapAlign: 'start',
            // wrapAround: true,
          },
          900: {
            itemsToShow: 1.8,
            snapAlign: 'start',
            // wrapAround: true,
          },
          1000: {
            itemsToShow: 1.7,
            snapAlign: 'start',
            // wrapAround: true,
          },
          1200: {
            itemsToShow: 2.3,
            snapAlign: 'start',
            // wrapAround: true,
          },
        }"
      >
        <Slide v-for="(plan, index) in plans" :key="index">
          <div class="carousel__item carousel__item__container">
            <img :src="plan.url" style="width: 100%" />
            <button
              class="btn btn-primary"
              @click="downloadPlan(plan.url, plan.name)"
            >
              Descargar
            </button>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "firebase/compat/storage";

export default {
  name: "PlanesDisponibles",
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  data() {
    return {
      carouselConfig: {
        itemsToShow: 2.5,
        wrapAround: true,
      },
      plans: [],
      loading: true,
    };
  },
  methods: {
    async getPlans() {
      this.loading = true;
      const plans = await this.$appFirebase.storage().ref("planes").list();
      this.plans = await Promise.all(
        plans.items.map(async (item) => {
          return {
            name: item.name,
            url: await item.getDownloadURL(),
            ext: item.name.split(".")[1],
            index: item.name.split("_")[0],
          };
        }),
      );
      this.plans.sort((a, b) => a.index - b.index);
      console.log("this.plans", this.plans);
      this.loading = false;
    },
    downloadPlan(url, fileName) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", url);
      xhr.send();
    },
  },
  async mounted() {
    await this.getPlans();
  },
};
</script>

<style scoped>
h1 {
  color: #f9cc03;
  text-align: center;
  font-family: Ubuntu;

  color: #7d0c7e;
  font-size: 20px;
  width: fit-content;
  text-shadow:
    2px 2px 0px white,
    -2px -2px 0px white,
    2px -2px 0px white,
    -2px 2px 0px white;
  background: #f9cc03;
  border-radius: 12px;
  padding: 3px 12px;
  box-shadow: 0px 2px 10px 2px #7c0c7e48;
  margin: 12px auto 32px auto;
}
.carouselContainer {
  padding-top: 20px;
  height: 80vh;
  width: 100%;
}
.carousel__item__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
</style>
