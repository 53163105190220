<template>
  <div class="card" style="margin-bottom: 10px; background-color: #ffffff99">
    <div class="card-body" style="background-color: transparent">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p
            style="
              margin: 10px;
              font-family: Ubuntu;
              font-size: 14px;
              font-weight: 900;
              color: #7d0c7e;
            "
          >
            <!-- <i style="margin-right: 10px" class="fas fa-list"></i>  -->
            Lista de Mi Equipo Primer Nivel
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="level1Referrers"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th data-priority="1">Fecha/Hora</th>
            <th data-priority="2">Nombre</th>
            <th>Contacto</th>
          </tr>
        </thead>

        <!-- <tbody>
          <tr v-for="(referrer, index) in level1Referrers" :key="index">
            <td style="color: black !important; background-color:">
              {{ referrer.dateRegister }}
            </td>
            <td>{{ `${referrer.names} ${referrer.lastnames}` }}</td>
            <td>{{ referrer.phone }}</td>
          </tr>
        </tbody> -->
      </DataTable>
    </div>
  </div>
  <div class="card" style="margin-bottom: 10px; background-color: #ffffff99">
    <div class="card-body" style="background-color: transparent">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p
            style="
              margin: 10px;
              font-family: Ubuntu;
              font-size: 14px;
              font-weight: 900;
              color: #7d0c7e;
            "
          >
            <!-- <i style="margin-right: 10px" class="fas fa-list"></i>  -->
            Lista de Mi Equipo Segundo Nivel
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="level2Referrers"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th data-priority="1">Fecha/Hora</th>
            <th data-priority="2">Nombre</th>
            <th>Contacto</th>
          </tr>
        </thead>

        <!-- <tbody>
          <tr v-for="referrer in level2Referrers" :key="referrer.userId">
            <td>{{ referrer.dateRegister }}</td>
            <td>{{ referrer.displayNameReferido }}</td>
            <td>{{ referrer.contacto }}</td>
          </tr>
        </tbody> -->
      </DataTable>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";

DataTable.use(DataTablesCore);

export default {
  name: "Referidos",
  components: {
    DataTable,
  },
  props: ["userId", "tokenAuth"],
  data() {
    return {
      loading: true,
      level1Referrers: [],
      level2Referrers: [],
      columns: [
        { data: "dateRegister" },
        {
          data: null,
          render: function (data, type, row, meta) {
            return `${data.names} ${data.lastnames}`;
          },
        },
        { data: "phone" },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "All"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  methods: {
    refresh() {
      this.$eventBus.emit("reiniciarListadoReferidos");
    },

    getListado() {
      this.$https
        .post("/referidos/fetchReferrals/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.loading = false;

            this.level1Referrers = response.data.level1Referrers;
            this.level2Referrers = response.data.level2Referrers;
          } else {
            if (response.data.code == 401) {
              // Redirect to login page
            } else {
              if (response.data.code == 404) {
                this.loading = false;
              } else {
                this.getListado();
              }
            }
          }
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          console.log("Error:", error.status);
          // this.getListado();
        });
    },
  },
  mounted() {
    this.getListado();
  },
};
</script>

<style scoped>
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
/* Target the <thead> to style the background color */
table thead {
  background-color: #7d0c7e; /* Change to your desired background color */
  color: #ffffff; /* Change font color */
}

/* Optional: Target the <th> elements for more specific styling */
table thead th {
  font-weight: bold; /* Make the text bold */
  padding: 10px; /* Adjust padding for better spacing */
  text-align: left; /* Align text if needed */
}

/* If you want to style a specific table */
table.dataTable thead th {
  background-color: #7d0c7e; /* Specific background color for this table */
  color: white; /* Specific font color */
}

:deep(table tbody tr td) {
  font-weight: bold !important;
}

:deep(.form-control.form-control-sm) {
  border: 2px solid #f9cc03;
  background: white;
}

:deep(.dataTables_filter label) {
  color: #7d0c7e;
}

:deep(.dataTables_length label) {
  color: #7d0c7e;
  justify-content: flex-start !important;
}
:deep(.dataTables_length label select) {
  border: 2px solid #f9cc03;
  background-color: #fff;
}
</style>
