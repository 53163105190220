<template>
  <div
    class="card"
    style="
      margin: 10px 0;
      background-color: #ffffff99;
      border: 2px solid #ec62ea;
    "
  >
    <div class="card-body" style="background-color: transparent">
      <div class="row">
        <div
          v-if="!onDashboard"
          class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11"
        >
          <p
            style="
              margin: 10px;
              font-family: Ubuntu;
              font-size: 14px;
              font-weight: 900;
              color: #7d0c7e;
            "
          >
            <!-- <i style="margin-right: 10px" class="fas fa-list"></i> -->
            Listado de últimos referidos
          </p>
        </div>
        <div
          v-if="!onDashboard"
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
        "
      >
        <img
          src="../../assets/img/Xtrim/ingresa-tus-nuevos-referidos-aqui.webp"
          style="width: 100%; max-width: 550px"
        />
        <div
          style="
            position: relative;
            display: flex;
            justify-content: center;
            width: fit-content;
          "
        >
          <img
            src="../../assets/img/Xtrim/manito-izq.png"
            style="position: absolute; top: 0; left: 0"
            class="scaling-image"
          />

          <button
            v-if="onDashboard"
            @click="registerReferral"
            class="pinkPurpleText"
          >
            Ingresar
          </button>
          <img
            src="../../assets/img/Xtrim/manito-der.png"
            style="position: absolute; top: 0; right: 0"
            class="scaling-image"
          />
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="sales"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th data-priority="3">Fecha/Hora</th>
            <th data-priority="1">Referido</th>
            <th>Celular</th>
            <th>Estado</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import ModalCompletarVenta from "./ModalCompletarVenta.vue";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    ModalCompletarVenta,
  },
  props: ["userId", "tokenAuth", "onDashboard", "registerReferral"],
  data() {
    return {
      loading: true,
      sales: [],
      componentKeyModalCompletarVenta: 100,
      idVenta: "",
      showModal: false,
      columns: [
        { data: "dateFormatted", width: "20%" },
        {
          data: null,
          width: "30%",
          render: function (data) {
            return `${data.displayName}  ${
              data.notes?.length > 0
                ? "<br> <b>Nota: </b>" + data.notes[data.notes.length - 1].note
                : ""
            }`;
          },
        },
        { data: "phone", width: "20%" },
        { data: "status", width: "30%" },
      ],
      options: {
        responsive: true,
        ordering: false,
        lengthMenu: [
          [5, 15, 25, 35, 50, -1],
          [5, 15, 25, 35, 50, "All"],
        ],
        pageLength: 5,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "No tienes referidos ¡Refiere ya!",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$eventBus.on("reiniciarModalCompletarVenta", () => {
      this.componentKeyModalCompletarVenta += 1;
      this.showModal = false;
    });

    this.getListado();
  },
  methods: {
    refresh() {
      this.$eventBus.emit("reiniciarListadoVentas");
    },

    getListado() {
      this.$https
        .post("/ventas/fetchSales/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          this.loading = false;

          this.sales = response.data.sales;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else if (error.status === 404) {
            this.loading = false;
            return;
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          this.loading = false;

          // this.getListado();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";

table thead {
  background-color: #7d0c7e; /* Change to your desired background color */
  color: #ffffff; /* Change font color */
}

/* Optional: Target the <th> elements for more specific styling */
table thead th {
  font-weight: bold; /* Make the text bold */
  padding: 10px; /* Adjust padding for better spacing */
  text-align: left; /* Align text if needed */
}

/* If you want to style a specific table */
table.dataTable thead th {
  background-color: #7d0c7e; /* Specific background color for this table */
  color: white; /* Specific font color */
}

:deep(table tbody tr td) {
  font-weight: bold !important;
}

:deep(.form-control.form-control-sm) {
  border: 2px solid #f9cc03;
  background: white;
}

:deep(.dataTables_filter label) {
  color: #7d0c7e;
}

:deep(.dataTables_length label) {
  color: #7d0c7e;
}
:deep(.dataTables_length label select) {
  border: 2px solid #f9cc03;
  background-color: white;
}

:deep(.dataTables_length) {
  display: none;
}

:deep(.dataTables_filter) {
  display: none;
}

.pinkPurpleText {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Ubuntu;
  margin: 0;
  width: max-content;
  border-radius: 16px;
  gap: 8px;
  margin-top: 8px;
  font-weight: 900;
  margin-left: 70px;
  margin-right: 70px;
  box-shadow: 0px 2px 10px 2px #bc49be48;
}

.pinkPurpleText:hover {
  background: linear-gradient(
    68deg,
    rgb(226, 80, 232) 0%,
    rgba(170, 50, 189, 1) 100%
  );
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.scaling-image {
  max-height: 34px;
  animation: scaleAnimation 1.5s ease-in-out infinite;
}
</style>
